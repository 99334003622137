import React from "react";

export const StepsHeader = ({ children }) => {
  return <div className="bs-stepper-header">{children}</div>;
};

export const NewStepHeader = ({
  activeStep,
  stepNumber,
  stepTitle,
  stepSubtitle,
  handleClick,
  disabled = false,
}) => {
  return (
    <div
      className={`step ${activeStep === stepNumber ? "active" : ""}`}
      data-target="#account-details-1"
    >
      <button
        type="button"
        className="step-trigger"
        onClick={() => handleClick(stepNumber)}
        disabled={disabled}
      >
        <span className="bs-stepper-circle">{stepNumber}</span>
        <span className="bs-stepper-label">
          <span className="bs-stepper-title">{stepTitle}</span>
          <span className="bs-stepper-subtitle">{stepSubtitle}</span>
        </span>
      </button>
    </div>
  );
};
