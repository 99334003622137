import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  aibotApiStdUrl,
  aibotApiUrl,
  credentials,
  env,
  envTypes,
} from "../constants/constants";
import Cookies from "js-cookie";
import { getCookieByDomain } from "../utils/utils";

export const userApi = createApi({
  reducerPath: "userApi",

  baseQuery: fetchBaseQuery({
    baseUrl: aibotApiStdUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getCookieByDomain("crave_jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: credentials,
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => `find_all_user_details/`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Users", id })),
              { type: "Users", id: "UsersList" },
            ]
          : [{ type: "Users", id: "UsersList" }],
    }),
    addUsers: builder.mutation({
      query: (body) => ({
        url: `user-space/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "Users", id: "UsersList" }],
    }),
    updateUser: builder.mutation({
      query: ({ body, email }) => ({
        url: `user-space/${email}`,
        method: "PUT",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "Users", id: "UsersList" }],
      onQueryStarted: async ({ body, email }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          userApi.util.updateQueryData("getUsers", undefined, (draft) => {
            const userToUpdate = draft.find(
              (user) => user.user_email === email
            );
            if (userToUpdate) {
              Object.assign(userToUpdate, body);
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteUser: builder.mutation({
      query: (email) => ({
        url: `user/${email}/`,
        method: "DELETE",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      // invalidatesTags: [{ type: "Users", id: "UsersList" }],
      async onQueryStarted(email, { dispatch, queryFulfilled, getCacheEntry }) {
        console.log("🚀 ~ onQueryStarted ~ email:", email);
        let patchResult;
        try {
          patchResult = dispatch(
            userApi.util.updateQueryData("getUsers", undefined, (draft) => {
              const index = draft.findIndex((item) => {
                console.log("🚀 ~ index ~ item:", item);

                return item.user_email === email;
              });
              if (index > -1) {
                draft.splice(index, 1);
              }
            })
          );
        } catch (error) {
          console.log(error);
          if (patchResult && patchResult.undo) {
            patchResult.undo();
          }
        }
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useAddUsersMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
} = userApi;
