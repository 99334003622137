import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  aibotApiStdUrl,
  apiDomain,
  credentials,
  domain,
  env,
} from "../constants/constants";
import { current } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getCookieByDomain } from "../utils/utils";

const boundary = Math.random().toString(36).substring(2, 15);

export const collectionApi = createApi({
  reducerPath: "collectionApi",

  baseQuery: fetchBaseQuery({
    baseUrl: apiDomain,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getCookieByDomain("crave_jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: credentials,
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getCollections: builder.query({
      query: (currentSpace) => `list_collection/${currentSpace}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Collection", id })),
              { type: "Collection", id: "CollectionsList" },
            ]
          : [{ type: "Collection", id: "CollectionsList" }],
    }),

    getCollectionFiles: builder.query({
      query: (collection_id) => `list_files_details/${collection_id}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "CollectionFiles", id })),
              { type: "CollectionFiles", id: "CollectionFilesList" },
            ]
          : [{ type: "CollectionFiles", id: "CollectionFilesList" }],
    }),

    downloadFile: builder.query({
      _query: (filePath) => ({
        url: `download_s3_file?s3_full_path=${encodeURIComponent(filePath)}`,
        responseHandler: (response) => response.blob(),
      }),
      get query() {
        return this._query;
      },
      set query(value) {
        this._query = value;
      },
      cache: "no-cache",
    }),

    getCollectionsById: builder.query({
      query: (id) => `collections_id/${id}`,
    }),
    addCollection: builder.mutation({
      query: (body) => ({
        url: `embed_files`,
        method: "POST",
        body: body,
        headers: {
          // accept: "application/json",
          // "Content-Type": `multipart/form-data`,
        },
        // formData:true
      }),
      invalidatesTags: [
        { type: "Collection", id: "CollectionsList" },
        { type: "CollectionFiles", id: "CollectionFilesList" },
      ],
    }),
    addScrapeCollection: builder.mutation({
      query: (body) => ({
        url: `scrape`,
        method: "POST",
        body: body,
        headers: {
          // accept: "application/json",
          // "Content-Type": `multipart/form-data`,
        },
        // formData:true
      }),
      invalidatesTags: [
        { type: "Collection", id: "CollectionsList" },
        { type: "CollectionFiles", id: "CollectionFilesList" },
      ],
    }),
    updateCollection: builder.mutation({
      query: ({ body, Collection_id }) => ({
        url: `collections/${Collection_id}`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(
        { body, Collection_id },
        { dispatch, queryFulfilled, getCacheEntry }
      ) {
        const patchResult = dispatch(
          collectionApi.util.updateQueryData(
            "getCollections",
            body.space_id,
            (draft) => {
              console.log(Collection_id, "cache");
              const copy = current(draft);
              console.log(copy, "cp");
              const updated = copy.map((item) => {
                console.log("neeraj2");

                const newObj = { ...item };
                console.log(
                  item.Collection_id === Collection_id,
                  "item.Collection_id === Collection_id"
                );
                if (item.Collection_id === Number(Collection_id)) {
                  console.log("neeraj");
                  newObj["name"] = body.name;
                  newObj["type"] = body.type;
                  newObj["config"] = body.config;

                  console.log(newObj, "mew");
                }
                return newObj;
              });

              Object.assign(draft, updated);
            }
          )
        );
        const patchResult2 = dispatch(
          collectionApi.util.updateQueryData(
            "getCollectionsById",
            Collection_id,
            (draft) => {
              const copy = current(draft);
              console.log(copy, "cpcp");

              const newObj = { ...copy };
              newObj["name"] = body.name;
              newObj["type"] = body.type;
              newObj["config"] = body.config;

              console.log(newObj, "newObj");
              Object.assign(draft, newObj);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error, "error");
          patchResult.undo();
        }
      },
    }),

    deleteFileFromCollection: builder.mutation({
      query: (body) => ({
        url: `delete_file_from_collection?filepath=${body.id}`,
        method: "DELETE",
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getCacheEntry }) {
        let patchResult;
        try {
          patchResult = dispatch(
            collectionApi.util.updateQueryData(
              "getCollectionFiles",
              body.collection_id,
              (draft) => {
                const index = draft.findIndex((item) => {
                  return encodeURIComponent(item.file_location) === body.id;
                });
                if (index > -1) {
                  draft.splice(index, 1);
                }
              }
            )
          );
        } catch (error) {
          console.log(error);
          if (patchResult && patchResult.undo) {
            patchResult.undo();
          }
        }
      },
    }),

    deleteCollection: builder.mutation({
      query: (body) => ({
        url: `delete_collection?collection_id=${body.id}`,
        method: "DELETE",
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getCacheEntry }) {
        let patchResult;
        try {
          patchResult = dispatch(
            collectionApi.util.updateQueryData(
              "getCollections",
              body.currentSpace,
              (draft) => {
                const index = draft.findIndex((item) => {
                  console.log(item.collection_id, "data");
                  return item.collection_id === body.id;
                });
                if (index > -1) {
                  draft.splice(index, 1);
                }
              }
            )
          );
        } catch (error) {
          console.log(error);
          if (patchResult && patchResult.undo) {
            patchResult.undo();
          }
        }
      },
    }),

    updateMetaData: builder.mutation({
      query: (body) => ({
        url: `update-meta/`,
        method: "PUT",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getCacheEntry }) {
        let patchResult;
        try {
          patchResult = dispatch(
            collectionApi.util.updateQueryData(
              "getCollectionFiles",
              body.collection_id,
              (draft) => {
                const copy = current(draft);
                console.log(copy, "cp");
                const updated = copy.map((item) => {
                  console.log("neeraj2");

                  const newObj = { ...item };

                  if (item.file_location === body.file_location) {
                    newObj["meta"] = body.meta;

                    console.log(newObj, "mew");
                  }
                  return newObj;
                });

                Object.assign(draft, updated);
              }
            )
          );
        } catch (error) {
          console.log(error);
          if (patchResult && patchResult.undo) {
            patchResult.undo();
          }
        }
      },
    }),
  }),
});

export const updateGetCollectionByIdCache = (id, newData) => {
  // collectionApi.endpoints.getCollectionsById.invalidateQuery(id);
  collectionApi.util.updateQueryData("getCollectionsById", id, () => newData);
};

export const {
  useGetCollectionsQuery,
  useGetCollectionFilesQuery,
  useDeleteCollectionMutation,
  useAddCollectionMutation,
  useGetCollectionsByIdQuery,
  useUpdateCollectionMutation,
  useAddScrapeCollectionMutation,
  useUpdateMetaDataMutation,
  useDeleteFileFromCollectionMutation,
  useLazyDownloadFileQuery,
} = collectionApi;
