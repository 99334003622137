import React from "react";

const PostPerPage = ({ limit, setPostPerPage, setCurrentPage }) => {
  return (
    <div className="dataTables_length" id="DataTables_Table_0_length">
      <label>
        <select
          name="DataTables_Table_0_length"
          aria-controls="DataTables_Table_0"
          className="form-select"
          onChange={(e) => {
            setPostPerPage(Number(e.target.value));
            // setCurrentPage(1);
          }}
          value={limit}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </label>
    </div>
  );
};

export default PostPerPage;
