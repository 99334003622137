import React from "react";
import styles from "./dropdown.module.css";

const Dropdown = ({
  value,
  label,
  name,
  onChange,
  disabled = false,
  options = [],
  placeholder = "Select an option",
  defaultValue = null,
}) => {
  return (
    <div className="form-group">
      {label && <label htmlFor={name}>{label}</label>}
      <select
        name={name}
        className="form-select"
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            disabled={option?.disabled || false}
            className={option?.disabled ? styles.disabledOption : ""}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
