import { getNestedValue } from "../../utils/utils";

export const generateReceiverConfig = (receiverObj) => {
  return {
    codeExecutionConfig: getNestedValue(
      receiverObj,
      ["config", "code_execution_config"],
      false
    ),
    defaultAutoReply: getNestedValue(
      receiverObj,
      ["config", "default_auto_reply"],
      ""
    ),
    humanInputMode: getNestedValue(
      receiverObj,
      ["config", "human_input_mode"],
      "NEVER"
    ),

    useCodeExecutionConfig: getNestedValue(
      receiverObj,
      ["config", "use_code_execution_config"],
      false
    ),
    useLlmConfig: getNestedValue(
      receiverObj,
      ["config", "use_llm_config"],
      false
    ),

    isTerminationMsg: getNestedValue(
      receiverObj,
      ["config", "is_termination_msg"],
      null
    ),
    cacheSeed: getNestedValue(
      receiverObj,
      ["config", "llm_config", "cache_seed"],
      null
    ),
    configList: getNestedValue(
      receiverObj,
      ["config", "llm_config", "config_list"],
      []
    ),
    workDir: getNestedValue(
      receiverObj,
      ["config", "code_execution_config", "work_dir"],
      ""
    ),
    useDocker: getNestedValue(
      receiverObj,
      ["config", "code_execution_config", "use_docker"],
      ""
    ),
    temperature: getNestedValue(
      receiverObj,
      ["config", "llm_config", "temperature"],
      0.1
    ),
    timeout: getNestedValue(
      receiverObj,
      ["config", "llm_config", "timeout"],
      null
    ),
    maxConsecutiveAutoReply: getNestedValue(
      receiverObj,
      ["config", "max_consecutive_auto_reply"],
      8
    ),
    name: getNestedValue(receiverObj, ["config", "name"], "group_chat_manager"),
    systemMessage: getNestedValue(
      receiverObj,
      ["config", "system_message"],
      ""
    ),
    description: getNestedValue(receiverObj, ["description"], ""),
    adminName: getNestedValue(
      receiverObj,
      ["groupchat_config", "admin_name"],
      "groupchat_assistant"
    ),
    agents: getNestedValue(receiverObj, ["groupchat_config", "agents"], []),
    allowRepeatSpeaker: getNestedValue(
      receiverObj,
      ["groupchat_config", "allow_repeat_speaker"],
      false
    ),
    maxRound: getNestedValue(
      receiverObj,
      ["groupchat_config", "max_round"],
      10
    ),
    messages: getNestedValue(receiverObj, ["groupchat_config", "messages"], []),
    speakerSelectionMethod: getNestedValue(
      receiverObj,
      ["groupchat_config", "speaker_selection_method"],
      "auto"
    ),
    skills: getNestedValue(receiverObj, ["skills"], []),
    timestamp: getNestedValue(receiverObj, ["timestamp"], ""),
    type: getNestedValue(receiverObj, ["type"], "groupchat"),
    userId: getNestedValue(receiverObj, ["user_id"], ""),
  };
};
