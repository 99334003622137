import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";

const CopyToClipboard = ({ textToCopy }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    });
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <Link to="#" className="text-body" onClick={handleCopy}>
        {copied ? (
          <>
            <span style={{ margin: "0px 5px" }}>
              <FaCheck />
            </span>
          </>
        ) : (
          <i className="ti ti-copy ti-sm mx-1"></i>
        )}
        <span
          style={{
            fontSize: "14px",
            visibility: copied ? "" : "hidden",
          }}
        >
          Copied!
        </span>
      </Link>
    </div>
  );
};

export default CopyToClipboard;
