import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  aibotApiStdUrl,
  aibotApiUrl,
  credentials,
  env,
} from "../constants/constants";
import Cookies from "js-cookie";
import { getCookieByDomain } from "../utils/utils";

export const rolesApi = createApi({
  reducerPath: "rolesApi",

  baseQuery: fetchBaseQuery({
    baseUrl: aibotApiStdUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getCookieByDomain("crave_jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: credentials,
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: () => `roles/`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Roles", id })),
              { type: "Roles", id: "RolesList" },
            ]
          : [{ type: "Roles", id: "RolesList" }],
    }),
    addRoles: builder.mutation({
      query: (body) => ({
        url: `Role/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "Roles", id: "RolesList" }],
    }),
    updateRole: builder.mutation({
      query: ({ body, email }) => ({
        url: `Role/${email}/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "Roles", id: "RolesList" }],
    }),
    deleteRole: builder.mutation({
      query: (email) => ({
        url: `Role/${email}/`,
        method: "DELETE",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "Roles", id: "RolesList" }],
    }),
  }),
});

export const {
  useGetRolesQuery,
  useAddRolesMutation,
  useDeleteRoleMutation,
  useUpdateRoleMutation,
} = rolesApi;
