import { configureStore, isRejectedWithValue } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { userApi } from "../services/userService";
import { spaceApi } from "../services/spaceService";
import { rolesApi } from "../services/roleService";
import { modelsApi } from "../services/modelsService";
import { databaseApi } from "../services/databaseService";
import { actionsApi } from "../services/actionsService";
import { agentsApi } from "../services/agentsService";
import { userSlice } from "../services/userSlice";
import { workforceApi } from "../services/workforceService";
import { fetchSpaces, spaceSlice } from "../services/spaceSlice";
import { collectionApi } from "../services/collectionService";
import notificationReducer from "../services/notificationSlice";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { loginPath } from "../constants/constants";
import { apiKeysAPI } from "../services/apiKeysService";

// import { aibotApi } from "../service/aibotService";
// import { actionsApi } from '../services/ActionsService';
const MySwal = withReactContent(Swal);

const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (
    isRejectedWithValue(action) &&
    (action.payload.status === 401 || action.payload.status === 403)
  ) {
    let timerInterval;

    MySwal.fire({
      title: "",
      html: "Your token has expired. You will be redirected to login",
      timer: 3000,
      timerProgressBar: true,
      showConfirmButton: true,
      // showCancelButton: true,
      icon: "error",
      confirmButtonText: `Login`,

      didOpen: () => {
        MySwal.showLoading();
        // const b = Swal.getHtmlContainer().querySelector("b");
        timerInterval = setInterval(() => {
          // b.textContent = MySwal.getTimerLeft();
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === MySwal.DismissReason.timer) {
        window.location.href = loginPath;
      }
    });
  }

  return next(action);
};

export const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [spaceApi.reducerPath]: spaceApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [modelsApi.reducerPath]: modelsApi.reducer,
    [databaseApi.reducerPath]: databaseApi.reducer,
    [actionsApi.reducerPath]: actionsApi.reducer,
    [agentsApi.reducerPath]: agentsApi.reducer,
    [workforceApi.reducerPath]: workforceApi.reducer,
    [collectionApi.reducerPath]: collectionApi.reducer,
    [apiKeysAPI.reducerPath]: apiKeysAPI.reducer,

    userAccess: userSlice.reducer,
    defaultSpace: spaceSlice.reducer,
    notification: notificationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      userApi.middleware,
      spaceApi.middleware,
      rolesApi.middleware,
      modelsApi.middleware,
      databaseApi.middleware,
      actionsApi.middleware,
      agentsApi.middleware,
      workforceApi.middleware,
      collectionApi.middleware,
      apiKeysAPI.middleware,
      rtkQueryErrorLogger
    ),
});

store.dispatch(fetchSpaces());

setupListeners(store.dispatch);
