import React from "react";
import { getInitials } from "../../utils/utils";

const Avatar = ({ children }) => {
  return (
    <div className="avatar me-3">
      <span className="avatar-initial rounded-circle bg-label-primary">
        {getInitials(children)}
      </span>
    </div>
  );
};

export default Avatar;
