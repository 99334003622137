import React, { useEffect, useState } from "react";
import InputField, { Textarea } from "../../Components/Input/Input";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { spaceTypeOptions } from "../../constants/spaces";

import isEmpty from "validator/es/lib/isEmpty";

import {
  PrimaryButton,
  SecondaryButton,
} from "../../Components/Buttons/Buttons";
import { Spinner } from "../../Components/Spinners/Spinners";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts/Alerts";
import {
  useAddSpaceMutation,
  useUpdateSpaceMutation,
} from "../../services/spaceService";

const AddNewSpace = ({
  setShowAddNewUser,
  addSpace: newSpace,
  space,
  setSpace,
  setAddSpace,
}) => {
  const [error, setError] = useState({
    name: "",
    type: "",
    desc: "",
  });

  const [addSpace, addSpaceInfo] = useAddSpaceMutation();
  const [updateSpace, updateSpaceInfo] = useUpdateSpaceMutation();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSpace((prevSpace) => ({
      ...prevSpace,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    setShowAddNewUser(false);
  };

  useEffect(() => {
    if (addSpaceInfo.isSuccess) {
      setSpace({
        name: "",
        type: "",
        desc: "",
      });
      setError({
        name: "",
        type: "",
        desc: "",
      });
    }
    if (updateSpaceInfo.isSuccess) {
      setError({
        name: "",
        type: "",
        desc: "",
      });
    }
  }, [addSpaceInfo, newSpace, setSpace, updateSpaceInfo]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let valid = true;
    let errors = {
      name: "",
      type: "",
      desc: "",
    };
    if (isEmpty(space.name)) {
      errors.name = "Please enter space name";
      valid = false;
    }

    if (isEmpty(space.desc)) {
      errors.desc = "Please enter space desc";
      valid = false;
    }

    if (valid) {
      const data = {
        name: space.name,
        description: space.desc,
        type: space.type || spaceTypeOptions[0].value,
        user_id: 25,
        role_id: 8,
      };
      if (newSpace) addSpace(data);
      else updateSpace({ body: data, space_id: space.spaceId });
    } else {
      setError(errors);
    }
  };

  return (
    <div
      className="offcanvas offcanvas-end show"
      tabIndex={-1}
      id="offcanvasAddUser"
      aria-labelledby="offcanvasAddUserLabel"
    >
      <div className="offcanvas-header">
        <h5 id="offcanvasAddUserLabel" className="offcanvas-title">
          {newSpace ? "Add Space" : "Edit Space"}
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => handleCancel()}
        />
      </div>
      <div className="offcanvas-body mx-0 flex-grow-0 pt-0 h-100">
        <form
          className="add-new-user pt-0 fv-plugins-bootstrap5 fv-plugins-framework"
          id="addNewUserForm"
          onSubmit={(e) => handleSubmit(e)}
          noValidate="novalidate"
        >
          <div className="mb-3 fv-plugins-icon-container">
            <InputField
              type="text"
              value={space.name}
              placeholder="Space Name"
              label="Space Name"
              name="name"
              onChange={handleChange}
            />
            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
              {error.name}
            </div>
          </div>
          <div className="mb-3 fv-plugins-icon-container">
            <Dropdown
              value={space.type}
              placeholder="Select Space Type"
              label="Space Type"
              name="type"
              onChange={handleChange}
              options={spaceTypeOptions}
              defaultValue={spaceTypeOptions[0]}
            />
            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
              {error.type}
            </div>
          </div>
          <div className="mb-3 fv-plugins-icon-container">
            <Textarea
              value={space.desc}
              placeholder="Desc..."
              label="Description"
              name="desc"
              onChange={handleChange}
            />

            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
              {error.desc}
            </div>
          </div>
          <InputField
            type="hidden"
            value={space.spaceId}
            placeholder="Space Type"
            label=""
            name="space_id"
            onChange={handleChange}
          />
          {addSpaceInfo.isLoading ? (
            <PrimaryButton
              handleClick={handleSubmit}
              type="submit"
              disabled={true}
            >
              Submitting
              <span style={{ marginLeft: "5px" }}>
                <Spinner classes={"spinner-border-sm"} />
              </span>
            </PrimaryButton>
          ) : (
            <PrimaryButton
              handleClick={(e) => {
                handleSubmit(e);
              }}
              type="submit"
            >
              Submit
            </PrimaryButton>
          )}

          <SecondaryButton handleClick={handleCancel}>Cancel</SecondaryButton>
        </form>

        <div className="mt-3">
          {(addSpaceInfo.isSuccess || updateSpace.isSuccess) && (
            <SuccessAlert>
              {console.log(addSpaceInfo.isSuccess, "addSpaceInfo.isSuccess ")}
              Space {addSpaceInfo.isSuccess ? "Added" : "Updated"} Successfully
            </SuccessAlert>
          )}
          {(addSpaceInfo.isError || updateSpace.isError) && (
            <ErrorAlert>Some Error Occured</ErrorAlert>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddNewSpace;
