import { Editor } from "@monaco-editor/react";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Spinner } from "react-bootstrap";
import {
  LoadingButton,
  PrimaryButton,
  SecondaryButton,
} from "../../Components/Buttons/Buttons";
import { jsonEditorOptions } from "../../utils/jsonEditorOption";
import { isEqual, cloneDeep, debounce } from "lodash";
import FullscreenEditor from "../../Components/FullScreenEditor/FullScreenEditor.jsx";

const WorkforceStep4 = ({
  setActiveStep,
  showValue,
  handleEditorDidMount,
  addActionInfo,
  updateActionInfo,
  validateActionInfo,
  data,
  handlePrev,
}) => {
  let [isApiKeyHidden, setIsApiKeyHidden] = useState(true);
  let [originalData, setOriginalData] = useState(data);
  const editorRef = useRef(null);
  let previousContentRef = useRef(JSON.stringify(data, null, 2));

  useEffect(() => {
    setOriginalData(data);
    previousContentRef.current = JSON.stringify(data, null, 2);
  }, [data]);

  const handlePrevClick = () => {
    handlePrev(3);
  };

  const handleSubmit = (validateConfig = true) => {
    setIsApiKeyHidden(false);
    setTimeout(
      () => {
        showValue(true, validateConfig);
      },
      isApiKeyHidden ? 1000 : 0
    );
  };

  const handleToggleApiKeyVisibility = () => {
    setIsApiKeyHidden((prev) => !prev);
  };

  const processApiKey = (obj, mask) => {
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        processApiKey(obj[key], mask);
      } else if (key === "api_key") {
        obj[key] = mask ? "***************************" : data[key];
      }
    }
  };

  const getModifiedData = () => {
    if (!originalData) return {};
    const modifiedData = JSON.parse(JSON.stringify(originalData));
    processApiKey(modifiedData, isApiKeyHidden);
    return modifiedData;
  };

  const handleEditorChange = (value) => {
    try {
      let newContent = JSON.parse(value);
      let previousContent = JSON.parse(previousContentRef.current);

      let changes = findUpdatedOrNewKeysAndValues(previousContent, newContent);

      let writableOriginalData = cloneDeep(originalData);

      changes.forEach(({ path, value }) => {
        if (!path.includes("api_key")) {
          updateNestedProperty(writableOriginalData, path, value);
        }
      });

      setOriginalData(writableOriginalData);
      previousContentRef.current = value;
    } catch (error) {
      console.error("Invalid JSON format", error);
    }
  };

  const updateNestedProperty = (obj, path, value) => {
    try {
      let keys = path.split(".");
      let nestedObj = obj;
      for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];

        if (!nestedObj[key]) {
          nestedObj[key] = {};
        }

        nestedObj = nestedObj[key];
      }

      const lastKey = keys[keys.length - 1];
      nestedObj[lastKey] = value;
    } catch (error) {
      console.error("Error updating nested property:", error);
    }
  };

  const findUpdatedOrNewKeysAndValues = (original, updated, path = "") => {
    let changes = [];

    for (let key in updated) {
      const currentPath = path ? `${path}.${key}` : key;

      if (!(key in original)) {
        changes.push({ path: currentPath, value: updated[key] });
      } else if (typeof updated[key] === "object" && updated[key] !== null) {
        const nestedChanges = findUpdatedOrNewKeysAndValues(
          original[key],
          updated[key],
          currentPath
        );
        if (nestedChanges.length > 0) {
          changes = changes.concat(nestedChanges);
        }
      } else if (!isEqual(original[key], updated[key])) {
        changes.push({ path: currentPath, value: updated[key] });
      }
    }

    return changes;
  };

  const debouncedEditorChange = useCallback(
    debounce(handleEditorChange, 500),
    []
  );

  return (
    <div id="personal-info-1" className="content content active dstepper-block">
      <div className="content-header mb-3 d-flex justify-content-between align-items-center">
        <div>
          <h6 className="mb-0">Configuration</h6>
          <small>Enter configuration in JSON format.</small>
        </div>

        <button
          className="btn btn-secondary btn-md"
          onClick={handleToggleApiKeyVisibility}
        >
          {isApiKeyHidden ? "Show API Key" : "Hide API Key"}
        </button>
      </div>

      <FullscreenEditor
        height="50vh"
        defaultLanguage="json"
        defaultValue={
          data && isApiKeyHidden
            ? JSON.stringify(getModifiedData(), null, 2)
            : JSON.stringify(originalData, null, 2)
        }
        theme="vs-dark"
        options={jsonEditorOptions}
        onMount={handleEditorDidMount}
        key={isApiKeyHidden}
        onChange={debouncedEditorChange}
      />
      <div className="col-12 d-flex justify-content-between mt-3">
        <SecondaryButton handleClick={handlePrevClick}>
          <i className="ti ti-arrow-left me-sm-1 me-0" /> Previous
        </SecondaryButton>
        {/* <div>
          {addActionInfo.isLoading || updateActionInfo.isLoading ? (
            <LoadingButton buttonText="Saving..." variant="primary" />
          ) : (
            <PrimaryButton handleClick={() => handleSubmit(false)}>
              <i className="ti ti-save me-sm-1 me-0" /> Save
            </PrimaryButton>
          )}
          {validateActionInfo.isLoading ? (
            <LoadingButton buttonText="Validating..." />
          ) : (
            <PrimaryButton
              handleClick={() => handleSubmit(true)}
              variant="submit"
            >
              Validate & Submit
            </PrimaryButton>
          )}
        </div> */}
        {addActionInfo.isLoading || updateActionInfo.isLoading ? (
          <LoadingButton />
        ) : (
          <PrimaryButton handleClick={handleSubmit} variant="submit">
            Submit{" "}
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

export default WorkforceStep4;
