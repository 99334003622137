import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { LoadingButton } from "../../Components/Buttons/Buttons";

const CopyRowModal = ({
  show,
  onHide,
  onCopy,
  existingNames,
  modalTitle,
  fieldName,
  prefilledName,
  isLoading = false,
}) => {
  const [newName, setNewName] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (show) {
      setNewName(prefilledName);
      setError("");
    }
  }, [show, prefilledName]);

  const handleCopy = async () => {
    if (existingNames.includes(newName.trim())) {
      setError("Name must be unique");
    } else if (newName.trim() === "") {
      setError("Name cannot be empty");
    } else {
      await onCopy(newName.trim());
      onHide();
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label style={{ fontSize: "16px" }}>{fieldName}</Form.Label>
          <Form.Control
            type="text"
            value={newName}
            onChange={(e) => {
              setNewName(e.target.value);
              setError("");
            }}
            placeholder="Enter a unique name"
            isInvalid={!!error}
            style={{ fontSize: "14px" }}
          />
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        {isLoading ? (
          <LoadingButton buttonText="Copying..." />
        ) : (
          <Button variant="primary" onClick={handleCopy}>
            Copy
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CopyRowModal;
