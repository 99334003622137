import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useFilters,
  useRowSelect,
} from "react-table";
import { Alert, Col, Row } from "react-bootstrap";
import styles from "./reacttable.module.css";
import {
  DOTS,
  usePagination as usePaginationRange,
} from "../../utils/usePaginations";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  // const count = preFilteredRows.length;
  // return (
  //   <input
  //     value={filterValue || ""}
  //     onChange={(e) => {
  //       setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
  //     }}
  //     placeholder={`Search ${count} record...`}
  //   />
  // );
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const ReactTable = ({
  columns,
  data,
  pageRows = 10,
  showPagination = true,
  disableFilters = true,
  allowRowSelect = false,
  setSelectedRows,
  getOriginal = false,
  setOrignalValues,
}) => {
  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 200, // width is used for both the flex-basis and flex-grow
      maxWidth: 200,
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,

    canPreviousPage,
    canNextPage,

    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,

    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data: data,

      defaultColumn,
      disableFilters: disableFilters,
      defaultCanFilter: false,
      autoResetPage: false,
      autoResetFilters: false,
    },

    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      allowRowSelect &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              // <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              // </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
    }

    // useBlockLayout
    // useFlexLayout
  );

  useEffect(() => {
    setPageSize(pageRows);
  }, [pageRows, setPageSize]);

  useEffect(() => {
    if (allowRowSelect) {
      console.log(selectedRowIds);
      setSelectedRows([...Object.keys(selectedRowIds)]);
    }
    console.log();
    if (getOriginal) {
      const originalVal = selectedFlatRows.map((d) => d.original);

      setOrignalValues(originalVal);
    }
  }, [
    allowRowSelect,
    getOriginal,
    selectedFlatRows,
    selectedRowIds,
    setOrignalValues,
    setSelectedRows,
  ]);

  const paginationRange = usePaginationRange({
    currentPage: pageIndex + 1,
    totalCount: rows.length,
    siblingCount: 1,
    pageSize: pageRows,
  });

  return (
    <>
      <table
        className="datatables-users table dataTable no-footer dtr-column react-table"
        id="DataTables_Table_0"
      >
        <thead className="border-top">
          {headerGroups.map((headerGroup) => (
            <tr>
              {headerGroup.headers.map((column) => (
                <th
                  // {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="sorting sorting_desc"
                >
                  {column.render("Header")}

                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                  <span style={{ margin: "5px" }}>
                    {column.canFilter ? column.render("Filter") : null}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} className="odd">
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      data-label={headerGroups[0].headers[index].Header}
                      className={cell.column.Header + " control"}
                      style={{
                        maxWidth: cell.column.Header !== "Action" && "18%",
                        whiteSpace: cell.column.Header === "Action" && "nowrap",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {allowRowSelect && (
        <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
      )}

      {page && page.length === 0 && (
        <Alert variant="primary mt-4 mx-3">No results found</Alert>
      )}
      {showPagination && (
        <Row className="row mx-2">
          <Col className="col-sm-12 col-md-6">
            <div className={styles.page_msg + " dataTables_info"}>
              {" "}
              Showing {pageIndex * pageSize + 1} to{" "}
              {data.length > (pageIndex + 1) * pageSize
                ? (pageIndex + 1) * pageSize
                : data.length}{" "}
              of {data.length} entries
            </div>
          </Col>

          <Col className="col-sm-12 col-md-6">
            <div className={styles.paginate}>
              <ul className={"pagination"}>
                <li
                  className={` ${
                    pageIndex === 0 ? styles.disabled : ""
                  }  paginate_button `}
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <Link
                    to="#"
                    aria-controls="DataTables_Table_0"
                    role="link"
                    data-dt-idx={1}
                    tabIndex={0}
                    className="page-link"
                  >
                    {" "}
                    &#8249; Prev
                  </Link>
                </li>
                {paginationRange.map((pageNumber) => {
                  if (pageNumber === DOTS) {
                    return (
                      <li key={pageNumber} className={`dots `}>
                        <Link
                          to="#"
                          aria-controls="DataTables_Table_0"
                          role="link"
                          data-dt-idx={1}
                          tabIndex={0}
                          className="page-link"
                        >
                          {" "}
                          &#8230;
                        </Link>
                      </li>
                    );
                  }

                  return (
                    <li
                      className={`${
                        pageNumber === pageIndex + 1 ? "active" : ""
                      } paginate_button page-item `}
                      key={pageNumber}
                      onClick={() => gotoPage(pageNumber - 1)}
                    >
                      <Link
                        to="#"
                        aria-controls="DataTables_Table_0"
                        role="link"
                        data-dt-idx={1}
                        tabIndex={0}
                        className="page-link"
                      >
                        {" "}
                        {pageNumber}
                      </Link>
                    </li>
                  );
                })}
                <li
                  className={`${
                    !canNextPage ? styles.disabled : ""
                  } paginate_button page-item `}
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <Link
                    to="#"
                    aria-controls="DataTables_Table_0"
                    role="link"
                    data-dt-idx={1}
                    tabIndex={0}
                    className="page-link"
                  >
                    {" "}
                    Next &#8250;
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default React.memo(ReactTable);
