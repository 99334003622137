import {
  AIWorkforceIcon,
  ActionIcon,
  KnowledgeIcon,
  ModelIcon,
} from "../../Icons/Icons";

export const Menu = {
  key: [
    {
      name: "Dashboard",
      icon: <i className="menu-icon tf-icons ti ti-smart-home" />,
      link: "/",
    },
    {
      name: "Users",
      icon: <i className="menu-icon tf-icons ti ti-users" />,
      link: "/user-management",
    },
    {
      name: "Space",
      icon: <i className="menu-icon tf-icons ti ti-layout-grid" />,
      link: "/manage-space",

      // submenu: [
      //   {
      //     name: "Select Space",
      //     link: "/space/select-space",
      //   },
      //   {
      //     name: "Manage Space",
      //     link: "/space/manage-space",
      //   },
      // ],
    },
  ],
  "Space Gallery": [
    {
      name: "AI Workforce",
      icon: <AIWorkforceIcon />,
      link: "/ai-workforce",
    },
    {
      name: "Agents",
      icon: <i className="menu-icon tf-icons ti ti-users" />,
      link: "/agents",
    },
    {
      name: "Actions",
      icon: <ActionIcon />,
      link: "/actions",
    },
    {
      name: "Models",
      icon: <ModelIcon />,
      link: "/models",
    },
    {
      name: "Databases",
      icon: <KnowledgeIcon />,
      link: "/database",
    },
    {
      name: "Collections",
      icon: <i className="menu-icon tf-icons ti ti-folder"></i>,
      link: "/collections",
    },
    // {
    //   name: "Environmental Variables",
    //   icon: <i class="menu-icon tf-icons ti ti-key"></i>,
    //   link: "/environmental-variables",
    // },
    // {
    //   name: "ML Projects",
    //   icon: <i className="menu-icon tf-icons ti ti-users" />,
    // },
  ],

  // "Shared Space": [
  //   {
  //     name: "Page 2",
  //     icon: <AIWorkforceIcon />,
  //   },
  //   // {
  //   //   name: "Agents",
  //   //   icon: <i className="menu-icon tf-icons ti ti-users" />,
  //   // },
  //   // {
  //   //   name: "Actions",
  //   //   icon: <ActionIcon />,
  //   // },
  //   // {
  //   //   name: "Models",
  //   //   icon: <ModelIcon />,
  //   // },
  //   // {
  //   //   name: "Knowledge",
  //   //   icon: <KnowledgeIcon />,
  //   // },
  //   // {
  //   //   name: "ML Projects",
  //   //   icon: <i className="menu-icon tf-icons ti ti-users" />,
  //   // },
  // ],
  // Others: [
  //   {
  //     name: "GenAI Responses",
  //     icon: <i className="menu-icon tf-icons  fa-solid fa-message" />,
  //   },
  //   // {
  //   //   name: "Agents",
  //   //   icon: <i className="menu-icon tf-icons ti ti-users" />,
  //   // },
  //   // {
  //   //   name: "Actions",
  //   //   icon: <ActionIcon />,
  //   // },
  //   // {
  //   //   name: "Models",
  //   //   icon: <ModelIcon />,
  //   // },
  //   // {
  //   //   name: "Knowledge",
  //   //   icon: <KnowledgeIcon />,
  //   // },
  //   // {
  //   //   name: "ML Projects",
  //   //   icon: <i className="menu-icon tf-icons ti ti-users" />,
  //   // },
  // ],
};
