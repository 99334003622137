import React from "react";

const InputField = ({
  value,
  label,
  name,
  placeholder,
  type,
  onChange,
  disabled = false,
  icon = false,
  onIconClick = null,
}) => (
  <div className="form-group position-relative">
    {label && <label htmlFor="input-field">{label}</label>}
    <input
      type={type}
      value={value}
      name={name}
      className="form-control"
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
    />
    {icon && (
      <span
        className="position-absolute top-50 end-0 translate-middle-y me-3"
        style={{ cursor: "pointer", marginTop: "10px" }}
        onClick={onIconClick}
      >
        <i style={{ fontSize: "24px" }} className={`${icon}`}></i>
      </span>
    )}
  </div>
);
export default InputField;

export const RadioField = ({
  value,
  label,
  name,
  placeholder,
  type,
  onChange,
  mainLabel,
}) => (
  <div className="form-group">
    {mainLabel && (
      <label
        htmlFor="input-field"
        className=""
        style={{ display: "block" }}
      ></label>
    )}
    <div style={{ marginTop: "28px" }}>
      {label && (
        <label htmlFor="input-field" className="form-check-label">
          {label}
        </label>
      )}
      <input
        type={type}
        checked={value}
        name={name}
        className="form-check-input mx-3"
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  </div>
);

export const SelectInput = ({
  options,
  value,
  onChange,
  label,
  name,
  withSelect = false,
}) => {
  return (
    <div className="form-group">
      {label && <label htmlFor="input-field">{label}</label>}

      <select
        name={name}
        className="form-select"
        value={value}
        onChange={onChange}
      >
        {withSelect && <option value="">Select</option>}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export const Textarea = ({
  value,
  label,
  name,
  placeholder,
  onChange,
  rows = 5,
  cols = 20,
}) => (
  <div className="form-group">
    {label && <label htmlFor="input-field">{label}</label>}

    <textarea
      name={name}
      cols={cols}
      rows={rows}
      className="form-control"
      onChange={onChange}
      placeholder={placeholder}
      value={value}
    ></textarea>
  </div>
);

export const HookInputField = ({
  value,
  label,
  name,
  placeholder,
  type,
  onChange,
  disabled = false,
  icon = false,
  onIconClick = null,
  register,
  required,
  setValue,
}) => (
  <div className="form-group position-relative">
    {label && <label htmlFor="input-field">{label}</label>}
    <input
      type={type}
      value={value}
      name={name}
      className="form-control"
      placeholder={placeholder}
      disabled={disabled}
      {...register(name, { required })}
      onChange={(e) => {
        onChange && onChange(e); // Call the onChange function if provided
        setValue(e.target.value); // Update the value using setValue
      }}
    />
    {icon && (
      <span
        className="position-absolute top-50 end-0 translate-middle-y me-3"
        style={{ cursor: "pointer", marginTop: "10px" }}
        onClick={onIconClick}
      >
        <i style={{ fontSize: "24px" }} className={`${icon}`}></i>
      </span>
    )}
  </div>
);
