import React from "react";

const ContentWrapper = ({ children }) => {
  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-y">{children}</div>
    </div>
  );
};

export default ContentWrapper;
