import { getNestedValue } from "../../utils/utils";

export const generateAgentConfig = (agentObj) => {
  return {
    codeExecutionConfig: getNestedValue(
      agentObj,
      ["config", "code_execution_config"],
      false
    ),
    defaultAutoReply: getNestedValue(
      agentObj,
      ["config", "default_auto_reply"],
      ""
    ),
    humanInputMode: getNestedValue(
      agentObj,
      ["config", "human_input_mode"],
      "NEVER"
    ),
    isTerminationMsg: getNestedValue(
      agentObj,
      ["config", "is_termination_msg"],
      null
    ),
    useLlmConfig: getNestedValue(agentObj, ["config", "use_llm_config"], false),
    useCodeExecutionConfig: getNestedValue(
      agentObj,
      ["config", "use_code_execution_config"],
      false
    ),
    cacheSeed: getNestedValue(
      agentObj,
      ["config", "llm_config", "cache_seed"],
      null
    ),
    configList: getNestedValue(
      agentObj,
      ["config", "llm_config", "config_list"],
      []
    ),
    temperature: getNestedValue(
      agentObj,
      ["config", "llm_config", "temperature"],
      0.1
    ),
    timeout: getNestedValue(
      agentObj,
      ["config", "llm_config", "timeout"],
      null
    ),
    maxConsecutiveAutoReply: getNestedValue(
      agentObj,
      ["config", "max_consecutive_auto_reply"],
      8
    ),
    systemMessage: getNestedValue(agentObj, ["config", "system_message"], ""),
    skills: getNestedValue(agentObj, ["skills"], []),
    type: getNestedValue(agentObj, ["type"], "assistant"),
    userId: getNestedValue(agentObj, ["user_id"], "guestuser@gmail.com"),
    name: getNestedValue(agentObj, ["name"], null),
    useDocker: getNestedValue(
      agentObj,
      ["config", "code_execution_config", "use_docker"],
      false
    ),
    workDir: getNestedValue(
      agentObj,
      ["config", "code_execution_config", "work_dir"],
      ""
    ),
  };
};
