import React from "react";

const CurrentStepHeading = ({ title, titleSummary }) => {
  return (
    <div className="content-header mb-3">
      <h6 className="mb-0">{title}</h6>
      <small>{titleSummary}</small>
    </div>
  );
};

export default CurrentStepHeading;
