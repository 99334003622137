import { placeholder } from "react-bootstrap";

export const field = {
  username: {
    name: "user",
    label: "Username",
    type: "text",
    validation: (value) => !!value,
    required: true,
    placeholder: "Enter username",
  },
  password: {
    name: "password",
    label: "Password",
    type: "password",
    validation: (value) => !!value,
    required: true,
    placeholder: "Enter password",
  },
  database: {
    name: "database",
    label: "Database",
    type: "text",
    validation: (value) => !!value,
    required: false,
    placeholder: "Enter database name",
  },

  url: {
    name: "url",
    label: "URL",
    type: "text",
    validation: (value) => !!value,
    required: true,
    placeholder: "Enter url name",
  },

  account: {
    name: "account",
    label: "Account",
    type: "text",
    validation: (value) => !!value,
    required: true,
    placeholder: "Enter account name",
  },

  port: {
    name: "port",
    label: "Port",
    type: "text",
    validation: (value) => !!value,
    required: true,
    placeholder: "Enter port name",
  },

  warehouse: {
    name: "warehouse",
    label: "Warehouse",
    type: "text",
    validation: (value) => !!value,
    required: true,
    placeholder: "Enter warehouse name",
  },
  role: {
    name: "role",
    label: "Role",
    type: "text",
    validation: (value) => !!value,
    required: true,
    placeholder: "Enter role name",
  },
  schema: {
    name: "schema",
    label: "Schema",
    type: "text",
    validation: (value) => !!value,
    required: true,
    placeholder: "Enter schema name",
  },
  host: {
    name: "host",
    label: "Host",
    type: "text",
    validation: (value) => !!value,
    required: true,
    placeholder: "Enter host name",
  },
};

export const databaseConfigs = {
  snowflake: {
    fields: [
      field.account,
      field.role,
      field.warehouse,
      field.database,
      field.username,
      field.password,
    ],
    label: "Snowflake",
  },
  postgres: {
    fields: [
      field.host,
      field.port,
      field.warehouse,
      field.schema,
      field.username,
      field.password,
    ],
    label: "Postgres",
  },
  chromaDB: {
    fields: [field.host, field.port],
    label: "ChromaDB",
  },
};
