import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useGetUsersQuery } from "../../services/userService";
import makeAnimated from "react-select/animated";
import { getInitials } from "../../utils/utils";
import Avatar from "../../Components/Avatar/Avatar";
import { Col, Dropdown, Spinner } from "react-bootstrap";
import { PrimaryButton } from "../../Components/Buttons/Buttons";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts/Alerts";
import isEmpty from "validator/es/lib/isEmpty";
import { useUpdateSpaceUsersMutation } from "../../services/spaceService";

const animatedComponents = makeAnimated();

const getSelectedRoles = (selectedUsers) => {
  const roles = {};
  selectedUsers.forEach((user) => {
    roles[user.user_id] = user.role_id;
  });
  return roles;
};
const ShareModal = ({
  setShowManageUsers,
  showManageUsers,
  usersData,
  selectedUsers,
  usersRoles,
  spaceId,
}) => {
  console.log(selectedUsers, "usersData");
  const options = usersData.map(({ user_id, user_email, user_name }) => ({
    value: user_id,
    label: `${user_name} (${user_email})`,
    name: `${user_name}`,
    email: `${user_email}`,
    isSelected: selectedUsers.some((user) => user.user_id === user_id),
  }));
  const [selectedOption, setSelectedOption] = useState([
    ...options.filter((option) => option.isSelected),
  ]);

  const [userRoles, setUserRoles] = useState(getSelectedRoles(selectedUsers)); // State to hold selected roles for each user

  const [error, setError] = useState("");

  const defaultValue = [
    ...options.filter((option) => option.isSelected), // Preselected options
  ];
  const [addSpaceUsers, addSpaceUsersInfo] = useUpdateSpaceUsersMutation();

  const handleChange = (selected) => {
    let difference = selectedOption.filter((x) => !selected.includes(x)); // calculates diff

    if (difference.length > 0 && Object.keys(userRoles).length > 0) {
      const roles = { ...userRoles };
      delete roles[difference[0].value];
      setUserRoles(roles);
    }

    console.log(difference, "diff");
    setSelectedOption(selected);
  };

  const handleSelect = (userId, role) => {
    setUserRoles((prevState) => ({
      ...prevState,
      [userId]: role, // Update selected role for the specific user
    }));
  };

  const handleSubmit = () => {
    console.log("Selected Roles:", userRoles, Object.keys(userRoles).length);
    if (selectedOption.length === 0) {
      setError("Please select atleast one user");
      return;
    }
    if (selectedOption.length > Object.keys(userRoles).length) {
      setError("Please select role for each user or remove them");
      return;
    }
    const body = [];

    selectedOption.map((option) => {
      const data = {};
      data["space_id"] = spaceId;
      data["user_id"] = option.value;
      data["role_id"] = userRoles[option.value];
      body.push(data);
    });
    addSpaceUsers(body);
  };

  return (
    <div
      className="modal fade show"
      id="shareProject"
      tabIndex={-1}
      style={{ display: "block" }}
      data-select2-id="shareProject"
      aria-modal="true"
      role="dialog"
    >
      <div
        className="modal-dialog modal-lg modal-simple modal-enable-otp modal-dialog-centered"
        data-select2-id={88}
      >
        <div className="modal-content p-3 p-md-5" data-select2-id={87}>
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setShowManageUsers(false)}
            />
            <div className="text-center">
              <h3 className="mb-2">Manage Space Users</h3>
              <p>Add or remove team members </p>
            </div>
          </div>
          <div className="mb-4 pb-2">
            <label htmlFor="select2Basic" className="form-label">
              Add Members
            </label>
            <div className="position-relative" data-select2-id={86}>
              <div className="position-relative" data-select2-id={97}>
                <div className="position-relative">
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    defaultValue={defaultValue}
                    isMulti
                    options={options}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <h4 className="mb-4 pb-2">{selectedOption.length} Members</h4>
          <ul className="p-0 m-0">
            {selectedOption.map((user) => (
              <li key={user.email} className="d-flex flex-wrap mb-3">
                <div className="avatar me-3">
                  <Avatar>{user.name}</Avatar>
                </div>
                <div className="d-flex justify-content-between flex-grow-1">
                  <div className="me-2">
                    <p className="mb-0">{user.name}</p>
                    <p className="mb-0 text-muted">{user.email}</p>
                  </div>
                  <Dropdown
                    className="dropdown"
                    onToggle={(isOpen) => {
                      if (!isOpen) return; // Ignore if dropdown is closing
                      handleSelect(user.value, userRoles[user.value] || null); // Pass selected role or null if none selected
                    }}
                  >
                    <Dropdown.Toggle
                      variant="outline-secondary"
                      id={`dropdown-basic-${user.id}`}
                    >
                      <span className="text-muted fw-normal me-2 d-none d-sm-inline-block">
                        {userRoles[user.value]
                          ? usersRoles.find(
                              (role) => role.role_id === userRoles[user.value]
                            ).name
                          : "Select Role"}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu-end">
                      {usersRoles.map((role) => (
                        <Dropdown.Item
                          key={role.id}
                          onClick={() => handleSelect(user.value, role.role_id)}
                        >
                          {role.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </li>
            ))}
          </ul>
          <Col xs={12} className="text-center">
            <div className="mb-3">
              {addSpaceUsersInfo.isSuccess && (
                <SuccessAlert>User Updated Successfully</SuccessAlert>
              )}
              {addSpaceUsersInfo.isError && (
                <ErrorAlert>Some Error Occured</ErrorAlert>
              )}
            </div>

            {!isEmpty(error) && <ErrorAlert>{error}</ErrorAlert>}
            {addSpaceUsersInfo.isLoading ? (
              <PrimaryButton
                handleClick={handleSubmit}
                type="submit"
                disabled={true}
              >
                Submitting
                <span style={{ marginLeft: "5px" }}>
                  <Spinner classes={"spinner-border-sm"} />
                </span>
              </PrimaryButton>
            ) : (
              <PrimaryButton handleClick={handleSubmit} type="submit">
                Submit
              </PrimaryButton>
            )}
          </Col>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
