import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { aibotApiStdUrl, credentials, env } from "../constants/constants";
import { current } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getCookieByDomain } from "../utils/utils";

export const modelsApi = createApi({
  reducerPath: "modelsApi",

  baseQuery: fetchBaseQuery({
    baseUrl: aibotApiStdUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getCookieByDomain("crave_jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: credentials,
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getModels: builder.query({
      query: (currentSpace) => `models/${currentSpace}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Models", id })),
              { type: "Models", id: "ModelsList" },
            ]
          : [{ type: "Models", id: "ModelsList" }],
    }),
    getModelsById: builder.query({
      query: (id) => `model_id/${id}`,
    }),
    addModel: builder.mutation({
      query: (body) => ({
        url: `models/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "Models", id: "ModelsList" }],
    }),
    updateModel: builder.mutation({
      query: ({ body, model_id }) => ({
        url: `models/${model_id}`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(
        { body, model_id },
        { dispatch, queryFulfilled, getCacheEntry }
      ) {
        const patchResult = dispatch(
          modelsApi.util.updateQueryData(
            "getModels",
            body.space_id,
            (draft) => {
              const copy = current(draft);
              const updated = copy.map((item) => {
                const newObj = { ...item };

                if (item.model_id === Number(model_id)) {
                  newObj["model"] = body.model;
                  newObj["description"] = body.description;
                  newObj["config"] = body.config;

                  console.log(newObj, "mew");
                }
                return newObj;
              });

              Object.assign(draft, updated);
            }
          )
        );
        const patchResult2 = dispatch(
          modelsApi.util.updateQueryData("getModelsById", model_id, (draft) => {
            const copy = current(draft);

            const newObj = { ...copy };
            newObj["model"] = body.model;
            newObj["description"] = body.description;
            newObj["config"] = body.config;
            Object.assign(draft, newObj);
          })
        );
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error, "error");
          patchResult.undo();
        }
      },
    }),

    deleteModel: builder.mutation({
      query: (body) => ({
        url: `models/${body.id}/`,
        method: "DELETE",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getCacheEntry }) {
        let patchResult;
        try {
          patchResult = dispatch(
            modelsApi.util.updateQueryData(
              "getModels",
              body.currentSpace,
              (draft) => {
                const index = draft.findIndex((item) => {
                  console.log("🚀 ~ index ~ item:", item);

                  return item.model_id === body.id;
                });
                if (index > -1) {
                  draft.splice(index, 1);
                }
              }
            )
          );
        } catch (error) {
          console.log(error);
          if (patchResult && patchResult.undo) {
            patchResult.undo();
          }
        }
      },
    }),
  }),
});

export const {
  useGetModelsQuery,
  useDeleteModelMutation,
  useAddModelMutation,
  useGetModelsByIdQuery,
  useUpdateModelMutation,
} = modelsApi;
