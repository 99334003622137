import "./spinkit.css";
export const Spinner = ({ classes = "" }) => {
  return (
    <div className={"spinner-border  text-primary" + classes} role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};

export const CubeSpinner = () => {
  return (
    <div className="sk-fold sk-primary">
      <div className="sk-fold-cube" />
      <div className="sk-fold-cube" />
      <div className="sk-fold-cube" />
      <div className="sk-fold-cube" />
    </div>
  );
};
