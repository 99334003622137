import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Banner from "../../Components/Banner/Banner";
import ReactTable from "../../Components/ReactTable/ReactTable";
import { CubeSpinner } from "../../Components/Spinners/Spinners";
import { ErrorAlert } from "../../Components/Alerts/Alerts";
import styles from "./aiworkforce.module.css";

import {
  filterByVal,
  truncateDescription,
  getStandardErrMsg,
} from "../../utils/utils";

import ContentWrapper from "../../Components/ContentWrapper/ContentWrapper";
import useConfirmDelete from "../../utils/useConfirmDelete";
import FillterWrapper from "../../Components/TableFilterWrapper/TableFillterWrapper";

import {
  useAddWorkforceMutation,
  useDeleteWorkforceMutation,
  useGetWorkforcesQuery,
} from "../../services/workforceService";
import { useSelector } from "react-redux";
import { heliosBot } from "../../constants/constants";
import useFilteredData from "../../utils/useFilteredData";

import CopyRowModal from "../../Components/CopyRowModal/CopyRowModal";

const AiWorkforceScreen = () => {
  const navigate = useNavigate();
  const currentSpace = useSelector((state) => state.defaultSpace.data);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [postsPerPage, setPostPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  // const [filteredPosts, setFilteredPosts] = useState([]);

  const { data, error, isFetching } = useGetWorkforcesQuery(currentSpace);
  const [deleteDatabase] = useDeleteWorkforceMutation();
  const confirmDelete = useConfirmDelete(deleteDatabase);
  const [addAction, addActionInfo] = useAddWorkforceMutation();

  const handleDelete = useCallback(
    (id) => {
      confirmDelete(id);
    },
    [confirmDelete]
  );

  const handleCopyClick = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const handleCopy = async (newName) => {
    const copiedData = { ...selectedRow };

    const body = {
      workforce_id: null,
      workforce_version_id: null,
      space_id: currentSpace,
      title: newName,
      description: copiedData.description,
      framework: copiedData.framework,
      share: copiedData.share,
      parent_id: 0,
      platform_default: true,
      version: "1.0",
      stage: copiedData.workforce_versions[0].stage,
      config: JSON.parse(copiedData.workforce_versions[0].config),
      users: [...copiedData.users.map((user) => user.user_id)],
      model_id: copiedData.workforce_versions[0].model_id,
      database_id: copiedData.workforce_versions[0].database_id,
    };

    await addAction(body);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "workforce_id",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Framework",
        accessor: "framework",
      },
      {
        Header: "Shared",
        Cell: ({ cell }) => (cell.row.original.share ? "Yes" : "No"),
      },
      {
        Header: "Desc",
        accessor: "description",
        Cell: ({ cell }) => (
          <> {truncateDescription(cell.row.original.description, 50)}</>
        ),
      },
      {
        Header: "Actions",
        Cell: ({ cell }) => (
          <>
            <Link
              to={`${heliosBot}?id=${cell.row.original.workforce_versions[0].workforce_version_id}`}
              className="text-body"
              target="_blank"
            >
              <i className="ti ti-player-play ti-sm me-2"></i>
            </Link>
            <Link
              to={`/ai-workforce/edit/${cell.row.original.workforce_id}`}
              className="text-body"
            >
              <i className="ti ti-edit ti-sm me-2" />
            </Link>
            <Link
              to="#"
              className="text-body"
              onClick={() => handleCopyClick(cell.row.original)}
            >
              <i className="ti ti-copy ti-sm mx-2"></i>
            </Link>
            <Link
              to="#"
              className="text-body delete-record"
              onClick={() => handleDelete(cell.row.original.workforce_id)}
            >
              <i className="ti ti-trash ti-sm mx-2" />
            </Link>
          </>
        ),
      },
    ],
    [handleDelete]
  );

  const filteredPosts = useFilteredData(data, searchText, filterByVal);

  const handleAddNew = () => {
    navigate("/ai-workforce/new");
  };

  return (
    <ContentWrapper>
      <Banner>AI Workforce</Banner>
      <div className="card mt-5">
        <FillterWrapper
          postsPerPage={postsPerPage}
          setPostPerPage={setPostPerPage}
          searchText={searchText}
          setSearchText={setSearchText}
          handleAddNew={handleAddNew}
          btnText="Workforce"
        />
        {isFetching ? (
          <div className={styles.cubeLoader}>
            {" "}
            <CubeSpinner />
          </div>
        ) : error ? (
          <>
            <ErrorAlert>{getStandardErrMsg(error)}</ErrorAlert>
          </>
        ) : (
          <ReactTable
            columns={columns}
            data={filteredPosts}
            pageRows={postsPerPage}
          />
        )}
      </div>
      {selectedRow && (
        <CopyRowModal
          show={showModal}
          onHide={() => setShowModal(false)}
          onCopy={handleCopy}
          existingNames={filteredPosts.map((workforce) => workforce.title)}
          modalTitle="Copy Workforce"
          fieldName="New Workforce name"
          prefilledName={`${selectedRow.title}_1`}
          isLoading={addActionInfo?.isLoading || false}
        />
      )}
    </ContentWrapper>
  );
};

export default AiWorkforceScreen;
