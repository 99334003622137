import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Banner from "../../Components/Banner/Banner";

import ReactTable from "../../Components/ReactTable/ReactTable";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { CubeSpinner } from "../../Components/Spinners/Spinners";
import { ErrorAlert } from "../../Components/Alerts/Alerts";
import styles from "./models.module.css";
// import SearchFilter from "../../Components/SearchFilter/SearchFilter";
// import isEmpty from "validator/es/lib/isEmpty";
import { filterByVal, getStandardErrMsg } from "../../utils/utils";
import {
  useDeleteModelMutation,
  useGetModelsQuery,
} from "../../services/modelsService";
import { useSelector } from "react-redux";
import ContentWrapper from "../../Components/ContentWrapper/ContentWrapper";
import FillterWrapper from "../../Components/TableFilterWrapper/TableFillterWrapper";
import useConfirmDelete from "../../utils/useConfirmDelete";
import useFilteredData from "../../utils/useFilteredData";

const MySwal = withReactContent(Swal);

const ModelsScreen = () => {
  const navigate = useNavigate();
  const currentSpace = useSelector((state) => state.defaultSpace.data);

  const [postsPerPage, setPostPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  // const [filteredPosts, setFilteredPosts] = useState([]);

  const { data, error, isFetching } = useGetModelsQuery(currentSpace);
  const [deleteModel] = useDeleteModelMutation();
  const confirmDelete = useConfirmDelete(deleteModel);

  const handleDelete = useCallback(
    (id) => {
      console.log("🚀 ~ ModelsScreen ~ id:", id);

      confirmDelete(id);
    },
    [confirmDelete]
  );

  // const handleDelete = useCallback(
  //   (user_email) => {
  //     MySwal.fire({
  //       title: "Are you sure?",
  //       text: "You won't be able to revert this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Delete",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         deleteModel(user_email);
  //       }
  //     });
  //   },
  //   [deleteModel]
  // );

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "model_id",
      },
      {
        Header: "Model",
        accessor: "model",
      },
      {
        Header: "Desc",
        accessor: "description",
      },
      {
        Header: "Actions",
        Cell: ({ cell }) => (
          <>
            <Link
              to={`/model/edit/${cell.row.original.model_id}`}
              className="text-body"
            >
              <i className="ti ti-edit ti-sm me-2" />
            </Link>
            <Link
              to="#"
              className="text-body delete-record"
              onClick={() => handleDelete(cell.row.original.model_id)}
            >
              <i className="ti ti-trash ti-sm mx-2" />
            </Link>
          </>
        ),
      },
    ],
    [handleDelete]
  );

  const filteredPosts = useFilteredData(data, searchText, filterByVal);

  const handleAddNew = () => {
    navigate("/model/new");
  };

  return (
    <ContentWrapper>
      <Banner>Models</Banner>
      <div className="card mt-5">
        <FillterWrapper
          postsPerPage={postsPerPage}
          setPostPerPage={setPostPerPage}
          searchText={searchText}
          setSearchText={setSearchText}
          handleAddNew={handleAddNew}
          btnText="Model"
        />
        {isFetching ? (
          <div className={styles.cubeLoader}>
            {" "}
            <CubeSpinner />
          </div>
        ) : error ? (
          <>
            <ErrorAlert>{getStandardErrMsg(error)}</ErrorAlert>
          </>
        ) : (
          <ReactTable
            columns={columns}
            data={filteredPosts}
            pageRows={postsPerPage}
          />
        )}
      </div>
    </ContentWrapper>
  );
};

export default ModelsScreen;
