import React from "react";
import Banner from "../../Components/Banner/Banner";
import { Col, Row } from "react-bootstrap";
import styles from "./selectspace.module.css";
import { useGetSpacesQuery } from "../../services/spaceService";
import { CubeSpinner } from "../../Components/Spinners/Spinners";
import {
  ErrorAlert,
  PrimayAlert,
  SuccessAlert,
} from "../../Components/Alerts/Alerts";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { saveSpace } from "../../services/spaceSlice";
import { getStandardErrMsg } from "../../utils/utils";

const SelectSpace = () => {
  const dispatch = useDispatch();
  const { data, error, isFetching } = useGetSpacesQuery();
  const currentSpace = useSelector((state) => state.defaultSpace.data);

  const handleSpaceSelect = (id) => {
    dispatch(saveSpace(id));
  };

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-y">
        <Banner>Your Spaces</Banner>
        <Row className={`row mt-5 ${styles.row}`}>
          {isFetching ? (
            <div className={styles.cubeLoader}>
              {" "}
              <CubeSpinner />
            </div>
          ) : error ? (
            <ErrorAlert>{getStandardErrMsg(error)}</ErrorAlert>
          ) : (
            data.map((space) => (
              <Col md={6} lg={4} className="mb-3">
                {currentSpace === space.space_id && (
                  <i
                    className="ti ti-circle-check-filled circle-check-filled f704"
                    style={{
                      float: "right",
                      marginRight: "-8px",
                      marginTop: "-10px",
                      color: "#0e8a46",
                      fontSize: 30,
                    }}
                  />
                )}

                {console.log(currentSpace, space.space_id, "space cm")}
                <div
                  className={`${styles.ProjectBox} ${
                    currentSpace === space.space_id
                      ? styles.bLight1Success
                      : styles.bLight1Primary
                  } h-100`}
                >
                  <span
                    className={`badge  ${styles.spaceBadge} ${
                      space.space_id === currentSpace
                        ? "bg-success"
                        : "bg-primary"
                    }`}
                  >
                    {space.type}
                  </span>
                  <h5 className="f-w-500">{space.name} </h5>

                  <p className={styles.description}>{space.description}</p>
                  <div className={`${styles.customers}`}></div>
                  <div className="text-center">
                    <button
                      type="button"
                      className={`btn  ${
                        space.space_id === currentSpace
                          ? "btn-success"
                          : "btn-primary"
                      } waves-effect waves-light`}
                      onClick={() => handleSpaceSelect(space.space_id)}
                      // disabled={space.space_id === currentSpace}
                    >
                      {space.space_id === currentSpace ? "Selected" : "Select"}
                    </button>
                  </div>
                </div>
              </Col>
            ))
          )}

          {data && data.length === 0 && (
            <PrimayAlert>
              No Spaces found.{" "}
              <Link to="/manage-space">
                <b>Let's create one</b>
              </Link>{" "}
            </PrimayAlert>
          )}
        </Row>
      </div>
    </div>
  );
};

export default SelectSpace;
