import React from "react";
import InputField, {
  RadioField,
  SelectInput,
  Textarea,
} from "../../Components/Input/Input";
import { Col, Row } from "react-bootstrap";

import makeAnimated from "react-select/animated";
import Select from "react-select";
import CurrentStepHeading from "../../Components/CurrentStepHeading/CurrentStepHeading";
import FieldWrapper from "../../Components/FieldWrapper/FieldWrapper";
import { PrimaryButton } from "../../Components/Buttons/Buttons";
import {
  booleanOptions,
  collectionOptValues,
  stageOptValue,
  userOptValues,
} from "../../utils/options";

const animatedComponents = makeAnimated();

const WorkforceStep1 = ({
  workforce,
  setWorkforce,
  data,
  usersData,
  isFetchingUsers,
  handleNext,
  error,
  collectionData,
  ragConfig,
  setRagConfig,
  isFetchingCollection,
}) => {
  const ragOptions = booleanOptions();

  const stageOptions = stageOptValue();

  const userOptions = userOptValues(usersData, data?.users);

  const collectionOptions = collectionOptValues(
    collectionData,
    ragConfig.content_filter.collection_id
  );

  const defaultValue = [
    ...userOptions.filter((option) => option.isSelected), // Preselected options
  ];

  const defaultCollectionValue = [
    ...collectionOptions.filter((option) => option.isSelected), // Preselected options
  ];

  const handleChange = (event) => {
    const { name, type, value, checked } = event.target;

    if (type === "checkbox") {
      setWorkforce((prevSpace) => ({
        ...prevSpace,
        [name]: checked,
      }));
    } else {
      setWorkforce((prevSpace) => ({
        ...prevSpace,
        [name]: value,
      }));
    }
  };

  const handleUserChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);
    setWorkforce((prevSpace) => ({
      ...prevSpace,
      users: [...selectedValues],
    }));
  };

  const handleRagChange = (e) => {
    const { name, type, value, checked } = e.target;

    if (type === "checkbox") {
      setRagConfig((prevSpace) => ({
        ...prevSpace,
        [name]: checked,
      }));
    } else {
      setRagConfig((prevSpace) => ({
        ...prevSpace,
        [name]: value,
      }));
    }
  };

  const handleCollectionRagChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);
    setRagConfig((prevSpace) => ({
      ...prevSpace,
      content_filter: { collection_id: [...selectedValues] },
    }));
  };
  const handleNextClick = () => {
    handleNext(2);
  };

  return (
    <>
      <CurrentStepHeading
        title="Workforce"
        titleSummary="Enter basic details"
      />

      <Row>
        <Col>
          <FieldWrapper errorMsg={error.title}>
            <InputField
              type="title"
              value={workforce.title}
              placeholder="Title"
              label="Title"
              name="title"
              onChange={handleChange}
            />
          </FieldWrapper>
        </Col>

        <Col>
          <FieldWrapper>
            <InputField
              type="text"
              value={"groupchat"}
              placeholder="type"
              label="Type"
              name="type"
              disabled={true}
              // onChange={handleChange}
            />
          </FieldWrapper>
        </Col>
        <FieldWrapper errorMsg={error.framework}>
          <InputField
            type="hidden"
            value={workforce.framework}
            placeholder="File"
            label=""
            name="framework"
            onChange={handleChange}
            disabled={true}
          />
        </FieldWrapper>
      </Row>

      <Row className="mb-3 ">
        <Col>
          <FieldWrapper errorMsg={error.stage}>
            <SelectInput
              options={stageOptions}
              value={workforce.stage}
              onChange={handleChange}
              label="Stage"
              name={"stage"}
            />
          </FieldWrapper>
        </Col>
        <Col>
          <FieldWrapper>
            <SelectInput
              options={ragOptions}
              value={workforce.ragAgent}
              onChange={handleChange}
              label="Rag Agent"
              name={"ragAgent"}
            />
          </FieldWrapper>
        </Col>
      </Row>

      {workforce.ragAgent && workforce.ragAgent !== "false" && (
        <Row className="mb-3 ">
          <Col xs={12} md={8}>
            <FieldWrapper errorMsg={error.users}>
              <label htmlFor="input-field">Select Collection</label>

              <Select
                closeMenuOnSelect={false}
                isMulti
                options={collectionOptions}
                onChange={handleCollectionRagChange}
                isLoading={isFetchingCollection}
                components={animatedComponents}
                defaultValue={defaultCollectionValue}
              />
            </FieldWrapper>
          </Col>
          <Col>
            <div className=" fv-plugins-icon-container">
              <RadioField
                type="checkbox"
                value={ragConfig.enabled}
                placeholder=""
                label="Enabled"
                name="enabled"
                onChange={handleRagChange}
                mainLabel="Enabled"
              />
            </div>
          </Col>
          <FieldWrapper>
            <Textarea
              value={ragConfig.system_message}
              placeholder="Planner. Thank like a Finance Manager for a Quick Service Restaurant (QSR) chain. Suggest a plan. The plan may involve an engineer who can write python code, an executor who execute code, critic to review the code and a writer who doesn't write code but write the summary/insights of the findings. Explain the plan first. Be clear which step is performed by the engineer, which step is performed by the executor, which step is performed by the critic and which step is performed by the writer. You pass the plan to the engineer."
              label="System Message"
              name="system_message"
              onChange={handleRagChange}
              rows={7}
            />
          </FieldWrapper>
        </Row>
      )}

      <Row className="mb-3 ">
        <Col xs={12} md={8}>
          <FieldWrapper errorMsg={error.users}>
            <label htmlFor="input-field">Manage Users</label>

            <Select
              closeMenuOnSelect={false}
              isMulti
              options={userOptions}
              onChange={handleUserChange}
              isLoading={isFetchingUsers}
              components={animatedComponents}
              defaultValue={defaultValue}
            />
          </FieldWrapper>
        </Col>
        <Col>
          <div className=" fv-plugins-icon-container">
            <RadioField
              type="checkbox"
              value={workforce.share}
              placeholder=""
              label="Share With Others"
              name="share"
              onChange={handleChange}
              mainLabel="Share"
            />
          </div>
        </Col>
      </Row>

      <FieldWrapper errorMsg={error.desc}>
        <Textarea
          value={workforce.desc}
          placeholder="Desc..."
          label="Description"
          name="desc"
          onChange={handleChange}
        />
      </FieldWrapper>

      <div className="col-12 d-flex justify-content-between">
        <PrimaryButton handleClick={handleNextClick}>
          Next <i className="ti ti-arrow-right" />
        </PrimaryButton>
      </div>
    </>
  );
};

export default WorkforceStep1;
