import React from "react";
import styles from "./banner.module.css";
import bannerImg from "../../assets/img/banner.jpg";
const Banner = ({ children }) => {
  return (
    // <div className="dropdown-menu-header " style={{ marginBottom: "5%" }}>
    <div
      className={`dropdown-header d-flex align-items-center py-3 ${styles.banner}`}
      style={{
        backgroundImage: `url(${bannerImg})`,
      }}
    >
      <div className={`${styles.heading}`}>
        <h2 className="text-body mb-0 me-auto">{children}</h2>
      </div>
    </div>

    // </div>
  );
};

export default Banner;
