import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Banner from "../../Components/Banner/Banner";
import ReactTable from "../../Components/ReactTable/ReactTable";
import { CubeSpinner } from "../../Components/Spinners/Spinners";
import { ErrorAlert } from "../../Components/Alerts/Alerts";
import styles from "./database.module.css";

import { filterByVal, getStandardErrMsg } from "../../utils/utils";

import {
  useDeleteDatabaseMutation,
  useGetDatabasesQuery,
} from "../../services/databaseService";

import ContentWrapper from "../../Components/ContentWrapper/ContentWrapper";
import useConfirmDelete from "../../utils/useConfirmDelete";
import FillterWrapper from "../../Components/TableFilterWrapper/TableFillterWrapper";
import { useSelector } from "react-redux";
import useFilteredData from "../../utils/useFilteredData";

const DatabaseScreen = () => {
  const navigate = useNavigate();
  const currentSpace = useSelector((state) => state.defaultSpace.data);

  const [postsPerPage, setPostPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  // const [filteredPosts, setFilteredPosts] = useState([]);

  const { data, error, isFetching } = useGetDatabasesQuery(currentSpace);
  const [deleteDatabase] = useDeleteDatabaseMutation();
  const confirmDelete = useConfirmDelete(deleteDatabase);

  const handleDelete = useCallback(
    (id) => {
      confirmDelete(id);
    },
    [confirmDelete]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "database_id",
      },
      {
        Header: "Database",
        accessor: "name",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Actions",
        Cell: ({ cell }) => (
          <>
            <Link
              to={`/database/edit/${cell.row.original.database_id}`}
              className="text-body"
            >
              <i className="ti ti-edit ti-sm me-2" />
            </Link>
            <Link
              to="#"
              className="text-body delete-record"
              onClick={() => handleDelete(cell.row.original.database_id)}
            >
              <i className="ti ti-trash ti-sm mx-2" />
            </Link>
          </>
        ),
      },
    ],
    [handleDelete]
  );

  const filteredPosts = useFilteredData(data, searchText, filterByVal);

  const handleAddNew = () => {
    navigate("/database/new");
  };

  return (
    <ContentWrapper>
      <Banner>Database</Banner>
      <div className="card mt-5">
        <FillterWrapper
          postsPerPage={postsPerPage}
          setPostPerPage={setPostPerPage}
          searchText={searchText}
          setSearchText={setSearchText}
          handleAddNew={handleAddNew}
          btnText="Database"
        />
        {isFetching ? (
          <div className={styles.cubeLoader}>
            {" "}
            <CubeSpinner />
          </div>
        ) : error ? (
          <>
            <ErrorAlert>{getStandardErrMsg(error)}</ErrorAlert>
          </>
        ) : (
          <ReactTable
            columns={columns}
            data={filteredPosts}
            pageRows={postsPerPage}
          />
        )}
      </div>
    </ContentWrapper>
  );
};

export default DatabaseScreen;
