import React, { useState, createPortal, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import bannerImg from "../../assets/img/banner.jpg";
import { useGetSpacesQuery } from "../../services/spaceService";
import { useDispatch, useSelector } from "react-redux";
import { CubeSpinner } from "../../Components/Spinners/Spinners";
import { ErrorAlert } from "../Alerts/Alerts";
import { Col } from "react-bootstrap";
import { saveSpace } from "../../services/spaceSlice";
import styles from "./navbar.module.css";
import { getStandardErrMsg } from "../../utils/utils";
const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openDropdown, setOpenDropdown] = useState(false);
  const { data, error, isFetching } = useGetSpacesQuery();
  const currentSpace = useSelector((state) => state.defaultSpace.data);
  console.log("🚀 ~ Navbar ~ currentSpace:", currentSpace);

  const handleSpaceSelect = (id) => {
    console.log("🚀 ~ handleSpaceSelect ~ id:", id);

    dispatch(saveSpace(id));
  };

  const handleClick = (e) => {
    e.preventDefault();
    navigate("/");
    setOpenDropdown(false);
  };

  const handleOutsideClick = () => {
    setOpenDropdown(false);
  };
  return (
    <nav
      className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
      id="layout-navbar"
    >
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
        <Link className="nav-item nav-link px-0 me-xl-4" to="#">
          <i className="ti ti-menu-2 ti-sm" />
        </Link>
      </div>
      <div
        className="navbar-nav-right d-flex align-items-center"
        id="navbar-collapse"
      >
        <div className="navbar-nav align-items-center">
          {/* <div className="nav-item navbar-search-wrapper mb-0">
            <Link
              className="nav-item nav-link search-toggler d-flex align-items-center px-0"
              to="#;"
            >
              <i className="ti ti-search ti-md me-2" />
              <span className="d-none d-md-inline-block text-muted">
                Search (Ctrl+/)
              </span>
            </Link>
          </div> */}
        </div>
        <ul className="navbar-nav flex-row align-items-center ms-auto">
          {/* User */}

          <li className="nav-item dropdown-shortcuts navbar-dropdown dropdown me-2 me-xl-0">
            <OutsideClickHandler onOutsideClick={handleOutsideClick}>
              <DropdownLink
                data={data}
                currentSpace={currentSpace}
                setOpenDropdown={setOpenDropdown}
                openDropdown={openDropdown}
              />
              {openDropdown && (
                <div
                  className={`dropdown-menu dropdown-menu-start r-0 py-0 ${
                    openDropdown ? "show" : ""
                  }`}
                  style={{ right: "0" }}
                >
                  <div className="dropdown-menu-header border-bottom">
                    <div
                      className="dropdown-header d-flex align-items-center py-3"
                      style={{
                        backgroundImage: `url(${bannerImg})`,
                        backgroundRepeat: "no-repeat",
                        width: "100%",
                        height: "100%",
                        backgroundPosition: "center",
                      }}
                    >
                      <h5
                        className={`"text-body mb-0 me-auto ${styles.shortcutHeading}`}
                      >
                        Space
                      </h5>
                      <Link
                        to="#"
                        className={`dropdown-shortcuts-add text-body ${styles.shortcutHeading}`}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        aria-label="Add shortcuts"
                        data-bs-original-title="Add shortcuts"
                      >
                        <i className="ti ti-sm ti-apps" />
                      </Link>
                    </div>
                  </div>
                  <div className="dropdown-shortcuts-list scrollable-container ps">
                    {isFetching ? (
                      <div>
                        {" "}
                        <CubeSpinner />
                      </div>
                    ) : error ? (
                      <ErrorAlert>{getStandardErrMsg(error)}</ErrorAlert>
                    ) : (
                      <ShortcutsGrid
                        shortcuts={data}
                        currentSpace={currentSpace}
                        handleSpaceSelect={handleSpaceSelect}
                      />
                    )}

                    <div className="dropdown-header d-flex align-items-center py-3">
                      <Link
                        to="/"
                        className="stretched-link"
                        style={{
                          textAlign: "center",
                          width: "100%",
                          margin: "0 auto",
                          fontSize: 14,
                          marginBottom: 0,
                          color: "#6f6b7d !important",
                          fontWeight: 600,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={(e) => handleClick(e)}
                      >
                        View All <i class="ti ti-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </OutsideClickHandler>
          </li>

          {/*/ User */}
        </ul>
      </div>
    </nav>
  );
};

const ShortcutsGrid = ({ shortcuts, currentSpace, handleSpaceSelect }) => {
  const rows = [];
  const maxItems = 4;

  const filteredShortcuts = shortcuts
    .filter((shortcut) => shortcut.space_id !== currentSpace)
    .slice(0, maxItems);

  for (let i = 0; i < filteredShortcuts.length; i += 2) {
    rows.push(
      <div className="row row-bordered overflow-visible g-0" key={i}>
        {filteredShortcuts.slice(i, i + 2).map((shortcut) => (
          <div
            className="dropdown-shortcuts-item col active"
            key={shortcut.space_id}
            onClick={() => handleSpaceSelect(shortcut.space_id)}
            style={{ cursor: "pointer" }}
          >
            <span className="dropdown-shortcuts-icon rounded-circle mb-2">
              <i
                className="menu-icon tf-icons ti ti-layout-grid"
                style={{ marginRight: 0 }}
              />
            </span>
            <Link
              to="#"
              className="stretched-link"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              {shortcut.name}
            </Link>
          </div>
        ))}
      </div>
    );
  }

  return <div>{rows}</div>;
};

const DropdownLink = ({
  data,
  currentSpace,
  setOpenDropdown,
  openDropdown,
}) => {
  const currentItem =
    data && data.length > 0
      ? data.find((item) => item.space_id === currentSpace)
      : null;

  return (
    <Link
      className="nav-link dropdown-toggle hide-arrow"
      to="#;"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
      onClick={() => setOpenDropdown(!openDropdown)}
      style={{
        display: "flex",
        alignItems: "center",
        gap: 3,
        fontWeight: 500,
        fontSize: "16px",
        color: "#6f6b7d",
      }}
    >
      <i className="ti tf-icons ti ti-layout-grid " />
      {currentItem && <span>{currentItem.name}</span>}
    </Link>
  );
};

const OutsideClickHandler = ({ children, onOutsideClick }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside of the component
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onOutsideClick();
      }
    };

    // Add event listener for mousedown
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onOutsideClick]);

  return <div ref={wrapperRef}>{children}</div>;
};
export default Navbar;
