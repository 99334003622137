import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { aibotApiStdUrl, credentials, env } from "../constants/constants";
import { current } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const apiKeysAPI = createApi({
  reducerPath: "apiKeysAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: aibotApiStdUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("crave_jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: credentials,
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getAPIKeys: builder.query({
      query: (currentSpace) => `environment_variables/${currentSpace}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "APIKeys", id })),
              { type: "APIKeys", id: "APIKeysList" },
            ]
          : [{ type: "APIKeys", id: "APIKeysList" }],
    }),

    addKey: builder.mutation({
      query: (body) => ({
        url: `environment_variables/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "APIKeys", id: "APIKeysList" }],
    }),
    updateKey: builder.mutation({
      query: ({ body, id }) => ({
        url: `environment_variables/${id}`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(
        { body, id },
        { dispatch, queryFulfilled, getCacheEntry }
      ) {
        const patchResult = dispatch(
          apiKeysAPI.util.updateQueryData(
            "getAPIKeys",
            body.space_id,
            (draft) => {
              const copy = current(draft);
              const updated = copy.map((item) => {
                const newObj = { ...item };

                if (item.environmental_variable_id === Number(id)) {
                  newObj["variable_name"] = body.variable_name;
                  newObj["value"] = body.value;

                  console.log(newObj, "mew");
                }
                return newObj;
              });

              Object.assign(draft, updated);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error, "error");
          patchResult.undo();
        }
      },
    }),

    deleteApiKey: builder.mutation({
      query: (body) => ({
        url: `environment_variables/${body.id}/`,
        method: "DELETE",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getCacheEntry }) {
        let patchResult;
        try {
          patchResult = dispatch(
            apiKeysAPI.util.updateQueryData(
              "getAPIKeys",
              body.currentSpace,
              (draft) => {
                const index = draft.findIndex((item) => {
                  return item.environmental_variable_id === body.id;
                });
                if (index > -1) {
                  draft.splice(index, 1);
                }
              }
            )
          );
        } catch (error) {
          console.log(error);
          if (patchResult && patchResult.undo) {
            patchResult.undo();
          }
        }
      },
    }),
  }),
});

export const {
  useGetAPIKeysQuery,
  useAddKeyMutation,
  useUpdateKeyMutation,
  useDeleteApiKeyMutation,
} = apiKeysAPI;
