import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { aibotApiStdUrl, credentials, env } from "../constants/constants";
import { current } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getCookieByDomain } from "../utils/utils";

export const agentsApi = createApi({
  reducerPath: "agentsApi",

  baseQuery: fetchBaseQuery({
    baseUrl: aibotApiStdUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getCookieByDomain("crave_jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: credentials,
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getAgents: builder.query({
      query: (currentSpace) => `agents/${currentSpace}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Agent", id })),
              { type: "Agent", id: "AgentsList" },
            ]
          : [{ type: "Agent", id: "AgentsList" }],
    }),
    getAgentsById: builder.query({
      query: (id) => `agents_id/${id}`,
    }),
    addAgent: builder.mutation({
      query: (body) => ({
        url: `agents/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "Agent", id: "AgentsList" }],
    }),
    updateAgent: builder.mutation({
      query: ({ body, agent_id }) => ({
        url: `agents/${agent_id}`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(
        { body, agent_id },
        { dispatch, queryFulfilled, getCacheEntry }
      ) {
        const patchResult = dispatch(
          agentsApi.util.updateQueryData(
            "getAgents",
            body.space_id,
            (draft) => {
              const copy = current(draft);

              const updated = copy.map((item) => {
                const newObj = { ...item };

                if (item.agent_id === Number(agent_id)) {
                  newObj["name"] = body.name;
                  newObj["description"] = body.description;
                  newObj["framework"] = body.framework;
                  newObj["share"] = body.share;

                  const agentVersion = JSON.parse(
                    JSON.stringify(newObj["agent_versions"])
                  );
                  agentVersion[0]["stage"] = body.stage;
                  agentVersion[0]["config"] = body.config;

                  newObj["agent_versions"] = agentVersion;
                }
                return newObj;
              });

              Object.assign(draft, updated);
            }
          )
        );
        const patchResult2 = dispatch(
          agentsApi.util.updateQueryData("getAgentsById", agent_id, (draft) => {
            const copy = current(draft);

            const newObj = { ...copy };
            newObj["name"] = body.name;
            newObj["description"] = body.description;
            newObj["framework"] = body.framework;
            newObj["share"] = body.share;

            const agentVersion = JSON.parse(
              JSON.stringify(newObj["agent_versions"])
            );
            agentVersion[0]["stage"] = body.stage;
            agentVersion[0]["config"] = JSON.stringify(body.config);

            newObj["agent_versions"] = agentVersion;

            Object.assign(draft, newObj);
          })
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),

    deleteAgent: builder.mutation({
      query: (body) => ({
        url: `agents/${body.id}/`,
        method: "DELETE",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getCacheEntry }) {
        let patchResult;
        try {
          patchResult = dispatch(
            agentsApi.util.updateQueryData(
              "getAgents",
              body.currentSpace,
              (draft) => {
                const index = draft.findIndex((item) => {
                  return item.agent_id === body.id;
                });
                if (index > -1) {
                  draft.splice(index, 1);
                }
              }
            )
          );
        } catch (error) {
          console.log(error);
          if (patchResult && patchResult.undo) {
            patchResult.undo();
          }
        }
      },
    }),
  }),
});

export const updateGetAgentByIdCache = (id, newData) => {
  // agentsApi.endpoints.getAgentsById.invalidateQuery(id);
  agentsApi.util.updateQueryData("getAgentsById", id, () => newData);
};

export const {
  useGetAgentsQuery,
  useDeleteAgentMutation,
  useAddAgentMutation,
  useGetAgentsByIdQuery,
  useUpdateAgentMutation,
} = agentsApi;
