import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: null,
  type: "success", // 'success', 'error'
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showNotification: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type || "success";
    },
    hideNotification: (state) => {
      state.message = null;
      state.type = "success";
    },
  },
});

export const { showNotification, hideNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
