import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./backbutton.module.css";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <button className={styles.backBtn} onClick={() => navigate(-1)}>
      {" "}
      <i class="ti ti-arrow-left"></i> Back
    </button>
  );
};

export default BackButton;
