import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { aibotApiStdUrl, credentials, env } from "../constants/constants";
import Cookies from "js-cookie";
import { getCookieByDomain } from "../utils/utils";

export const spaceApi = createApi({
  reducerPath: "spaceApi",

  baseQuery: fetchBaseQuery({
    baseUrl: aibotApiStdUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getCookieByDomain("crave_jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: credentials,
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getSpaces: builder.query({
      query: () => `spaces/`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Spaces", id })),
              { type: "Spaces", id: "SpacesList" },
            ]
          : [{ type: "Spaces", id: "SpacesList" }],
    }),
    addSpace: builder.mutation({
      query: (body) => ({
        url: `spaces/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "Spaces", id: "SpacesList" }],
    }),
    updateSpace: builder.mutation({
      query: ({ body, space_id }) => ({
        url: `spaces/${space_id}`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "Spaces", id: "SpacesList" }],
    }),
    updateSpaceUsers: builder.mutation({
      query: (body) => ({
        url: `space-users-list/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "Spaces", id: "SpacesList" }],
    }),
    deleteSpace: builder.mutation({
      query: (space_id) => ({
        url: `spaces/${space_id}/`,
        method: "DELETE",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(
        space_id,
        { dispatch, queryFulfilled, getCacheEntry }
      ) {
        console.log("🚀 ~ onQueryStarted ~ space_id:", space_id);
        let patchResult;
        try {
          patchResult = dispatch(
            spaceApi.util.updateQueryData("getSpaces", undefined, (draft) => {
              const index = draft.findIndex((item) => {
                console.log("🚀 ~ index ~ item:", item);

                return item.space_id === space_id;
              });
              if (index > -1) {
                draft.splice(index, 1);
              }
            })
          );
        } catch (error) {
          console.log(error);
          if (patchResult && patchResult.undo) {
            patchResult.undo();
          }
        }
      },
    }),
  }),
});

export const {
  useAddSpaceMutation,
  useGetSpacesQuery,
  useDeleteSpaceMutation,
  useUpdateSpaceMutation,
  useUpdateSpaceUsersMutation,
} = spaceApi;
