import React from "react";
import Logo from "../../assets/img/ai.png";

const SidebarLogo = ({ hiddenText = false, imageWidth = 40 }) => {
  return (
    <>
      <span className="app-brand-logo">
        <img src={Logo} width={imageWidth} height="auto" alt="" />
      </span>
      {!hiddenText && (
        <span
          className="app-brand-text demo menu-text fw-bold"
          style={{ color: "#dbdade", marginLeft: "0.3rem !important" }}
        >
          CraveAI
        </span>
      )}
    </>
  );
};

export default SidebarLogo;
