import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { hideNotification } from "../../services/notificationSlice";
import styles from "./Notification.module.css"; // Import CSS module

const Notification = () => {
  const dispatch = useDispatch();
  const { message, type } = useSelector((state) => state.notification);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (message) {
      setIsVisible(true);

      const timer = setTimeout(() => {
        setIsVisible(false);
        setTimeout(() => {
          dispatch(hideNotification());
        }, 500);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [message, dispatch]);

  if (!message) return null;

  return (
    <div
      className={`toast show position-fixed top-0 end-0 m-3 ${
        styles.notificationToast
      } ${isVisible ? "" : styles.slideOut} ${styles[type]}`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      style={{ minWidth: "300px" }}
    >
      <div className={`toast-header ${styles.header}`}>
        <strong className="me-auto">
          {type === "error" ? "Error" : "Success"}
        </strong>
        <button
          type="button"
          className="btn-close"
          onClick={() => dispatch(hideNotification())}
        ></button>
      </div>
      <div className={`toast-body ${styles.body}`}>{message}</div>
    </div>
  );
};

export default Notification;
