import React from "react";
import { Spinner } from "react-bootstrap";

export const PrimaryButton = ({
  type = "button",
  handleClick,
  children,
  style,
  disabled = false,
  variant = "primary",
}) => {
  return (
    <button
      type={type}
      className={`btn ${
        variant === "primary" ? "btn-primary" : " btn-success"
      } me-sm-3 me-1 data-submit waves-effect waves-light`}
      onClick={handleClick ? handleClick : undefined}
      style={style && style} // This part is fine
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export const SecondaryButton = ({
  type = "button",
  handleClick,
  children,
  style,
}) => {
  return (
    <button
      type={type}
      className="btn btn-label-secondary waves-effect"
      onClick={handleClick ? handleClick : undefined}
      style={style && style} // This part is fine
    >
      {children}
    </button>
  );
};

export const AddNewBtn = ({ handleAddNew, children, style }) => {
  return (
    <div className="dt-buttons btn-group flex-wrap">
      {" "}
      <button
        className="btn btn-secondary add-new btn-primary waves-effect waves-light"
        tabIndex={0}
        type="button"
        onClick={handleAddNew ? handleAddNew : undefined}
        style={style && style} // This part is fine
      >
        <span>
          <i className="ti ti-plus me-0 me-sm-1 ti-xs" />
          <span className="d-none d-sm-inline-block">{children}</span>
        </span>
      </button>{" "}
    </div>
  );
};

export const LoadingButton = ({
  buttonText = "Submitting",
  variant = "submit",
}) => {
  return (
    <PrimaryButton variant={variant}>
      {buttonText}
      <span style={{ marginLeft: "5px" }}>
        <Spinner classes={"spinner-border-sm"} />
      </span>
    </PrimaryButton>
  );
};
