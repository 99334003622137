// import { createSlice } from "@reduxjs/toolkit";
// import { defaultRegion } from "../utils/utils";

// export const spaceSlice = createSlice({
//   name: "space",
//   initialState: defaultRegion(),
//   // initialState: 1,

//   reducers: {
//     saveRegion: (state, action) => action.payload,
//   },
// });

import { createSlice } from "@reduxjs/toolkit";
import { useGetSpacesQuery } from "./spaceService";
import { aibotApiStdUrl } from "../constants/constants";

export const spaceSlice = createSlice({
  name: "space",
  initialState: {
    data: localStorage.getItem("defaultSpace")
      ? JSON.parse(localStorage.getItem("defaultSpace"))
      : null,
    loading: false,
    error: null,
  },
  reducers: {
    saveSpace: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      localStorage.setItem("defaultSpace", JSON.stringify(action.payload)); // Update localStorage
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { saveSpace, setError } = spaceSlice.actions;

// Using the RTK Query hook to fetch data
export const fetchSpaces = () => async (dispatch) => {
  console.log("fetch spaces called");
  try {
    if (!localStorage.getItem("defaultSpace")) {
      const response = await fetch(`${aibotApiStdUrl}spaces/`);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      if (data) {
        // If data is fetched successfully, update the state
        console.log(data, "from splice");
        dispatch(saveSpace(data[0].space_id));
      }
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    dispatch(setError("An error occurred while fetching data"));
  }
};

// Export the reducer
export default spaceSlice.reducer;

// export const { saveRegion } = spaceSlice.actions;
