import React, { useEffect, useRef } from "react";
import { HookInputField } from "../../Components/Input/Input";

import {
  LoadingButton,
  PrimaryButton,
  SecondaryButton,
} from "../../Components/Buttons/Buttons";

import { ErrorAlert, SuccessAlert } from "../../Components/Alerts/Alerts";
import { useForm } from "react-hook-form";
import {
  useAddKeyMutation,
  useUpdateKeyMutation,
} from "../../services/apiKeysService";
import { useSelector } from "react-redux";
import { useGetSpacesQuery } from "../../services/spaceService";

const formatValue = (value) => {
  const formattedValue = value
    .replace(/\s+/g, "_")
    .replace(/[^a-zA-Z0-9_]/g, "");
  return formattedValue;
};

const AddNewKey = ({ setShowAddNewUser, editKey, setEditKey }) => {
  const drawerRef = useRef(null);
  const currentSpace = useSelector((state) => state.defaultSpace.data);

  const { data: items, error, isLoading } = useGetSpacesQuery();

  const item = items?.find((item) => {
    return item.space_id === currentSpace;
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      variable_name: editKey?.variable_name
        ? editKey.variable_name.replace(formatValue(item.name) + "_", "")
        : "",

      variable_value: editKey?.value || "",
    },
  });

  const [addKey, addKeyInfo] = useAddKeyMutation();
  const [updateKey, updateKeyInfo] = useUpdateKeyMutation();

  const handleCancel = () => {
    setShowAddNewUser(false);
  };

  useEffect(() => {
    if (addKeyInfo.isSuccess || updateKeyInfo.isSuccess) {
      setEditKey({});
      reset({
        variable_name: "", // Resetting to an empty value
        variable_value: "", // Resetting to an empty value
      });
    }
  }, [addKeyInfo.isSuccess, updateKeyInfo.isSuccess, reset]);

  const onSubmit = (data) => {
    let variableName = formatValue(item.name) + "_" + data.variable_name;
    variableName = variableName.trim();
    const body = {
      space_id: currentSpace,
      variable_name: variableName,
      value: data.variable_value.trim(),
    };
    if (editKey) {
      updateKey({ body, id: editKey.environmental_variable_id });
      // setShowAddNewUser(false);
    } else {
      addKey(body);
    }
  };

  const handleVariableNameChange = (e) => {
    try {
      const value = e.target.value;
      setValue("variable_name", formatValue(value));
    } catch (error) {
      console.error("Error handling variable name change:", error);
    }
  };

  return (
    <div
      className="offcanvas offcanvas-end show"
      tabIndex={-1}
      id="offcanvasAddUser"
      aria-labelledby="offcanvasAddUserLabel"
      ref={drawerRef}
    >
      <div className="offcanvas-header">
        <h5 id="offcanvasAddUserLabel" className="offcanvas-title">
          {editKey ? "Edit " : "Add "} Environment Variable
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => handleCancel()}
        />
      </div>
      <div className="offcanvas-body mx-0 flex-grow-0 pt-0 h-100">
        <form
          className="add-new-user pt-0 fv-plugins-bootstrap5 fv-plugins-framework"
          id="addNewUserForm"
          onSubmit={handleSubmit(onSubmit)}
          noValidate="novalidate"
        >
          <div className="mb-3 fv-plugins-icon-container">
            <label htmlFor="input-field">Variable Name</label>
            <div className="input-group">
              <span className="input-group-text" id="basic-addon14">
                {formatValue(item?.name) + "_"}
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Enter your variable name"
                id="basic-url1"
                aria-describedby="basic-addon14"
                {...register("variable_name", { required: true })}
                onChange={(e) => {
                  handleVariableNameChange(e);
                  setValue(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="mb-3 fv-plugins-icon-container">
            <HookInputField
              label="Value"
              name="variable_value"
              placeholder="Enter your variable name"
              type="text"
              register={register}
              required={true}
              setValue={setValue}
            />
            {errors.variable_name && (
              <p className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                Variable value is required
              </p>
            )}
          </div>

          {addKeyInfo.isLoading || updateKeyInfo.isLoading ? (
            <LoadingButton />
          ) : (
            <PrimaryButton type="submit" variant="submit">
              Submit{" "}
            </PrimaryButton>
          )}

          <SecondaryButton handleClick={handleCancel}>Cancel</SecondaryButton>
        </form>

        <div className="mt-3">
          {(addKeyInfo.isSuccess || updateKeyInfo.isSuccess) && (
            <SuccessAlert>
              Environment variable {editKey ? "updated  " : "added "}
              successfully
            </SuccessAlert>
          )}
          {addKeyInfo.isError && <ErrorAlert>Some Error Occurred</ErrorAlert>}
        </div>
      </div>
    </div>
  );
};

export default AddNewKey;
