export const receiverObj = {
  config: {
    code_execution_config: false,
    default_auto_reply: "",
    human_input_mode: "NEVER",
    is_termination_msg: null,
    llm_config: {
      cache_seed: null,
      config_list: [],
      temperature: 0.1,
      timeout: null,
    },
    max_consecutive_auto_reply: 8,
    name: "group_chat_manager",
    system_message: "",
    use_llm_config: false,
    use_code_execution_config: false,
  },
  description: "",
  groupchat_config: {
    admin_name: "groupchat_assistant",
    agents: [],
    allow_repeat_speaker: false,
    max_round: 10,
    messages: [],
    speaker_selection_method: "auto",
  },
  skills: [],
  timestamp: "",
  type: "groupchat",
  user_id: "",
};
