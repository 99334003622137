export const senderObj = {
  config: {
    code_execution_config: false,
    default_auto_reply: "TERMINATE",
    human_input_mode: "NEVER",
    is_termination_msg: null,
    llm_config: false,
    max_consecutive_auto_reply: 5,
    name: "userproxy",
    system_message: "",
  },
  description: "",
  skills: [],
  type: "userproxy",
  user_id: "default",
};
