import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import InputField from "../../Components/Input/Input";
import isEmail from "validator/es/lib/isEmail";
import isEmpty from "validator/es/lib/isEmpty";
import {
  useAddUsersMutation,
  useUpdateUserMutation,
} from "../../services/userService";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../Components/Buttons/Buttons";
import { Spinner } from "../../Components/Spinners/Spinners";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts/Alerts";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { accountTypeOptions, platformRoleOptions } from "../../constants/users";
import { useGetRolesQuery } from "../../services/roleService";
import { useGetSpacesQuery } from "../../services/spaceService";
import { useUpdateSpaceUsersMutation } from "../../services/spaceService";
import styles from "./usermanagement.module.css";

const AddNewUser = ({ setShowAddNewUser, editUser, setEditUser }) => {
  const drawerRef = useRef(null);
  const currentSpace = useSelector((state) => state.defaultSpace.data);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setShowAddNewUser(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [setShowAddNewUser]);

  const [fullName, setFullName] = useState(editUser[0]);
  const [email, setEmail] = useState(editUser[1]);
  const [accountType, setAccountType] = useState(editUser[2]);
  const [fullNameError, setFullNameError] = useState("");
  const [emailError, setUserEmailError] = useState("");
  const [accountTypeError, setAccountTypeError] = useState("");
  const [isPlatformAdmin, setPlatformAdmin] = useState(false);
  const [platformRoleError, setPlatformRoleError] = useState("");
  const [spaceRoleError, setSpaceRoleError] = useState("");
  const [platformRole, setPlatformRole] = useState(
    isPlatformAdmin
      ? platformRoleOptions[0].value
      : platformRoleOptions[1].value
  );

  const [spacesRoles, setSpacesRoles] = useState(
    editUser?.[3] ? [...editUser[3]] : [{ space_id: currentSpace, role_id: "" }]
  );
  const [addSpaceUsers, addSpaceUsersInfo] = useUpdateSpaceUsersMutation();

  const { data: usersRoles = [], isFetching: isFetchingRoles } =
    useGetRolesQuery();
  const { data: spacesData = [], isFetching: isFetchingSpaces } =
    useGetSpacesQuery();

  const [addUser, addUserInfo] = useAddUsersMutation();
  const [updateUser, updateUserInfo] = useUpdateUserMutation();

  const handleChange = (index, name, value) => {
    if (name === "fullName") setFullName(value);
    else if (name === "email") setEmail(value);
    else if (name === "accountType") setAccountType(value);
    else {
      setSpacesRoles((prevSpacesRoles) => {
        const updatedSpacesRoles = prevSpacesRoles.map((role, idx) =>
          idx === index ? { ...role, [name]: parseInt(value) } : { ...role }
        );
        return updatedSpacesRoles;
      });
    }
  };

  const handleAddSpaceRole = () => {
    setSpacesRoles([...spacesRoles, { space_id: "", role_id: "" }]);
  };

  const handleDeleteSpaceRole = (index) => {
    const updatedSpacesRoles = spacesRoles.filter((_, i) => i !== index);
    setSpacesRoles(updatedSpacesRoles);
  };

  const handleCancel = () => {
    setShowAddNewUser(false);
  };

  useEffect(() => {
    if (isEmpty(editUser[0])) {
      if (addUserInfo.isSuccess) {
        setEmail("");
        setFullName("");
        setAccountType("");
      }
      if (updateUserInfo.isSuccess) {
        setEmail("");
        setFullName("");
        setAccountType("");
        setEditUser(["", ""]);
      }
    }
  }, [addUserInfo, updateUserInfo, setEditUser]);

  const hasEmptyStringValue = (arr) => {
    return arr.some((obj) => {
      return Object.values(obj).some((value) => value === "");
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
    if (isEmpty(fullName)) {
      setFullNameError("Please enter a valid name");
      valid = false;
    }
    if (!isEmail(email)) {
      setUserEmailError("Please enter a valid email");
      valid = false;
    }
    if (hasEmptyStringValue(spacesRoles)) {
      setSpaceRoleError("Please specify all spaces and roles");
      valid = false;
    }

    if (valid) {
      setFullNameError("");
      setSpaceRoleError("");
      setUserEmailError("");

      let data = {
        user_email: email,
        user_name: fullName,
        account_type: accountType || accountTypeOptions[0].value,
        space_details: [
          ...spacesRoles.map(({ space_id, role_id }) => ({
            space_id,
            role_id,
          })),
        ],
      };

      if (isPlatformAdmin) {
        data = {
          ...data,
          platform_user: platformRole || platformRoleOptions[0].value,
        };
      }

      if (isEmpty(editUser[0]) && isEmpty(editUser[1])) addUser(data);
      else updateUser({ body: data, email: editUser[1] });
    }
  };

  return (
    <div
      className="offcanvas offcanvas-end show"
      tabIndex={-1}
      id="offcanvasAddUser"
      aria-labelledby="offcanvasAddUserLabel"
      ref={drawerRef}
      style={{ width: "500px" }}
    >
      <div className="offcanvas-header">
        <h5 id="offcanvasAddUserLabel" className="offcanvas-title">
          {isEmpty(editUser[0]) && isEmpty(editUser[1])
            ? "Add User"
            : "Edit User"}
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => handleCancel()}
        />
      </div>
      <div className="offcanvas-body mx-0 flex-grow-0 pt-0 h-100 overflow-hidden">
        <form
          className="add-new-user pt-0 fv-plugins-bootstrap5 fv-plugins-framework"
          id="addNewUserForm"
          onSubmit={(e) => handleSubmit(e)}
          noValidate="novalidate"
        >
          <div className="mb-3 fv-plugins-icon-container">
            <InputField
              type="text"
              value={fullName}
              placeholder="John Doe"
              label="Full Name"
              name="fullName"
              onChange={(e) =>
                handleChange(null, e.target.name, e.target.value)
              }
            />
            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
              {fullNameError}
            </div>
          </div>
          <div className="mb-3">
            <InputField
              type="email"
              value={email}
              placeholder="john.doe@example.com"
              label="Email"
              name="email"
              onChange={(e) =>
                handleChange(null, e.target.name, e.target.value)
              }
            />
            <div className="invalid-feedback">{emailError}</div>
          </div>
          <div
            className={`mb-3 fv-plugins-icon-container ${
              isPlatformAdmin ? "" : "d-none"
            }`}
          >
            <Dropdown
              value={accountType}
              placeholder="Select Account Type"
              label="Account Type"
              name="accountType"
              onChange={(e) =>
                handleChange(null, e.target.name, e.target.value)
              }
              options={accountTypeOptions}
              defaultValue={accountTypeOptions[0].value}
            />
            <div className="invalid-feedback">{accountTypeError}</div>
          </div>
          <div className={styles.spaceRolesContainer}>
            {spacesRoles.map((item, index) => (
              <div key={index} className="row mb-3 align-items-end">
                <div className="col">
                  <Dropdown
                    value={item.space_id}
                    placeholder="Select Space"
                    label="Space"
                    name="space"
                    onChange={(e) =>
                      handleChange(index, "space_id", e.target.value)
                    }
                    options={spacesData.map((space) => ({
                      value: space.space_id,
                      label: space.name,
                      disabled: spacesRoles
                        .map(({ space_id }) => space_id)
                        .includes(space.space_id),
                    }))}
                  />
                </div>
                <div className="col">
                  <Dropdown
                    value={isFetchingRoles ? "loading" : item.role_id}
                    placeholder="Select Role"
                    label="Role"
                    name="role"
                    onChange={(e) =>
                      handleChange(index, "role_id", e.target.value)
                    }
                    options={
                      isFetchingRoles
                        ? [{ label: "Loading...", value: "loading" }]
                        : usersRoles.map((role) => ({
                            value: role.role_id,
                            label: role.name,
                            disabled: spacesRoles
                              .map(({ role_id }) => role_id)
                              .includes(role.role_id),
                          }))
                    }
                  />
                </div>
                <div className="col-auto">
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => handleDeleteSpaceRole(index)}
                    disabled={spacesRoles.length === 1}
                  >
                    <i className="ti ti-trash ti-sm mx-2" />
                  </button>
                </div>
              </div>
            ))}
          </div>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleAddSpaceRole}
          >
            Add Space
          </button>
          <div className="invalid-feedback">{spaceRoleError}</div>
          <div className="d-flex justify-content-end">
            {addUserInfo.isLoading || updateUserInfo.isLoading ? (
              <PrimaryButton type="submit" disabled={true}>
                Submitting
                <span style={{ marginLeft: "5px" }}>
                  <Spinner classes={"spinner-border-sm"} />
                </span>
              </PrimaryButton>
            ) : (
              <PrimaryButton type="submit" className="me-2">
                Submit
              </PrimaryButton>
            )}
            <SecondaryButton handleClick={handleCancel}>Cancel</SecondaryButton>
          </div>
        </form>

        <div className="mt-3">
          {(addUserInfo.isSuccess || updateUserInfo.isSuccess) && (
            <SuccessAlert>
              {console.log(addUserInfo.isSuccess, "addUserInfo.isSuccess ")}
              User {addUserInfo.isSuccess ? "Added " : "Updated "}Successfully
            </SuccessAlert>
          )}
          {(addUserInfo.isError || updateUserInfo.isError) && (
            <ErrorAlert>Some Error Occured</ErrorAlert>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddNewUser;
