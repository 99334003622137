import React, { useState } from "react";
import styles from "./aside.module.css";
import { IconCircleDot } from "@tabler/icons-react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "./Menu";
import SidebarLogo from "./SidebarLogo";
import { makeid } from "../../utils/utils";
import { useSelector } from "react-redux";

const Aside = () => {
  const [expandedMenu, setExpandedMenu] = useState(null);
  const space = useSelector((state) => state.defaultSpace);

  const location = useLocation();
  const currentUrl = location.pathname;
  console.log(currentUrl);
  const toggleMenu = (index) => {
    if (expandedMenu === index) {
      setExpandedMenu(null);
    } else {
      setExpandedMenu(index);
    }
  };

  return (
    <aside
      id="layout-menu"
      className="layout-menu menu-vertical menu ga-dark bg-menu-theme "
      data-bg-class="bg-menu-theme"
      style={{
        touchAction: "none",
        userSelect: "none",
        WebkitUserDrag: "none",
        WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
      }}
    >
      <div className="app-brand demo">
        <Link to="/" className="app-brand-link">
          <SidebarLogo />
        </Link>
        <Link
          to="#"
          className="layout-menu-toggle menu-link text-large ms-auto"
        >
          <i className="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle" />
          <i className="ti ti-x d-block d-xl-none ti-sm align-middle" />
        </Link>
      </div>

      <div className="menu-inner-shadow" />
      <ul className="menu-inner py-1 ps ps--active-y">
        {Object.entries(Menu).map(([key, value], mainIndex) => (
          <>
            {key === "Space Gallery" ? (
              space && (
                <>
                  {key !== "key" && (
                    <li
                      key={makeid(5)}
                      className="menu-header small text-uppercase"
                    >
                      <span className="menu-header-text">{key}</span>
                    </li>
                  )}
                  {value.map((item, index) => (
                    <li
                      key={makeid(6)}
                      className={`menu-item ${
                        expandedMenu === index + "" + mainIndex ? " open" : ""
                      } ${currentUrl === item.link ? "active" : ""}`}
                    >
                      <Link
                        to={item.link}
                        className={`menu-link ${
                          item.submenu ? "menu-toggle" : ""
                        }`}
                        onClick={() => toggleMenu(index + "" + mainIndex)}
                      >
                        <>{item.icon}</>
                        <div>{item.name}</div>
                      </Link>
                      {item.submenu &&
                        expandedMenu === index + "" + mainIndex && (
                          <ul className="menu-sub">
                            {item.submenu.map((subItem, subIndex) => (
                              <li
                                key={makeid(7)}
                                className={`menu-item ${
                                  currentUrl === subItem.link ? "active" : ""
                                }`}
                              >
                                {console.log(currentUrl, subItem.link)}
                                <Link
                                  to={subItem.link}
                                  className={`menu-link `}
                                >
                                  <div>{subItem.name}</div>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                    </li>
                  ))}
                </>
              )
            ) : (
              <>
                {key !== "key" && (
                  <li
                    key={makeid(5)}
                    className="menu-header small text-uppercase"
                  >
                    <span className="menu-header-text">{key}</span>
                  </li>
                )}
                {value.map((item, index) => (
                  <li
                    key={makeid(6)}
                    className={`menu-item ${
                      expandedMenu === index + "" + mainIndex ? " open" : ""
                    } ${currentUrl === item.link ? "active" : ""}`}
                  >
                    <Link
                      to={item.link}
                      className={`menu-link ${
                        item.submenu ? "menu-toggle" : ""
                      }`}
                      onClick={() => toggleMenu(index + "" + mainIndex)}
                    >
                      <>{item.icon}</>
                      <div>{item.name}</div>
                    </Link>
                    {item.submenu &&
                      expandedMenu === index + "" + mainIndex && (
                        <ul className="menu-sub">
                          {item.submenu.map((subItem, subIndex) => (
                            <li
                              key={makeid(7)}
                              className={`menu-item ${
                                currentUrl === subItem.link ? "active" : ""
                              }`}
                            >
                              {console.log(currentUrl, subItem.link)}
                              <Link to={subItem.link} className={`menu-link `}>
                                <div>{subItem.name}</div>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                  </li>
                ))}
              </>
            )}
          </>
        ))}
      </ul>
    </aside>
  );
};

export default React.memo(Aside);
