import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Banner from "../../Components/Banner/Banner";
import CopyToClipboard from "../../Components/CopyToClipboard/CopyToClipboard";
import ReactTable from "../../Components/ReactTable/ReactTable";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CubeSpinner } from "../../Components/Spinners/Spinners";
import { ErrorAlert } from "../../Components/Alerts/Alerts";
import styles from "./apikeys.module.css";
import { filterByVal } from "../../utils/utils";
import { useSelector } from "react-redux";
import ContentWrapper from "../../Components/ContentWrapper/ContentWrapper";
import FillterWrapper from "../../Components/TableFilterWrapper/TableFillterWrapper";
import useConfirmDelete from "../../utils/useConfirmDelete";
import useFilteredData from "../../utils/useFilteredData";
import {
  useDeleteApiKeyMutation,
  useGetAPIKeysQuery,
} from "../../services/apiKeysService";
import AddNewKey from "./AddNewKey";

const MySwal = withReactContent(Swal);

const ApiKeys = () => {
  const navigate = useNavigate();
  const currentSpace = useSelector((state) => state.defaultSpace.data);

  const [postsPerPage, setPostPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [showAddNewKey, setShowAddNewKey] = useState(false);
  const [editKey, setEditKey] = useState(null);

  const { data, error, isFetching } = useGetAPIKeysQuery(currentSpace);
  const [deleteKey] = useDeleteApiKeyMutation();
  const confirmDelete = useConfirmDelete(deleteKey);
  const [hoveredRow, setHoveredRow] = useState(null);

  const handleDelete = useCallback(
    (id) => {
      confirmDelete(id);
    },
    [confirmDelete]
  );

  const handleEdit = useCallback(
    (environmental_variable_id, variable_name, value) => {
      setEditKey({ environmental_variable_id, variable_name, value });
      setShowAddNewKey(true);
    },
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "environmental_variable_id",
      },
      {
        Header: "Variable Name",
        accessor: "variable_name",
        Cell: ({ cell }) => (
          <div
            onMouseEnter={() => setHoveredRow(cell.row.id)}
            onMouseLeave={() => setHoveredRow(null)}
          >
            <span>{cell.value}</span>
            <span
              style={{ visibility: hoveredRow === cell.row.id ? "" : "hidden" }}
            >
              <CopyToClipboard textToCopy={cell.value} />
            </span>
          </div>
        ),
      },
      {
        Header: "Variable Value",
        accessor: "value",
        Cell: ({ cell }) => (
          <div
            onMouseEnter={() => setHoveredRow(cell.row.id)}
            onMouseLeave={() => setHoveredRow(null)}
          >
            <span>{cell.value}</span>
            <span
              style={{ visibility: hoveredRow === cell.row.id ? "" : "hidden" }}
            >
              <CopyToClipboard textToCopy={cell.value} />
            </span>
          </div>
        ),
      },
      {
        Header: "Actions",
        Cell: ({ cell }) => (
          <div
            onMouseEnter={() => setHoveredRow(cell.row.id)}
            onMouseLeave={() => setHoveredRow(null)}
          >
            <Link
              to={`#`}
              className="text-body"
              onClick={() =>
                handleEdit(
                  cell.row.original.environmental_variable_id,
                  cell.row.original.variable_name,
                  cell.row.original.value
                )
              }
            >
              <i className="ti ti-edit ti-sm mx-1" />
            </Link>
            <Link
              to="#"
              className="text-body delete-record"
              onClick={() =>
                handleDelete(cell.row.original.environmental_variable_id)
              }
            >
              <i className="ti ti-trash ti-sm mx-1" />
            </Link>
          </div>
        ),
      },
    ],
    [handleDelete, handleEdit, hoveredRow]
  );

  const filteredPosts = useFilteredData(data, searchText, filterByVal);

  const handleAddNew = () => {
    setEditKey(null);
    setShowAddNewKey(true);
  };

  return (
    <ContentWrapper>
      <Banner>Environment Variables</Banner>
      <div className="card mt-5">
        {isFetching ? (
          <div className={styles.cubeLoader}>
            <CubeSpinner />
          </div>
        ) : error ? (
          <>
            <ErrorAlert>
              Some Error occurred. Please contact administrator
            </ErrorAlert>
          </>
        ) : (
          <>
            <FillterWrapper
              postsPerPage={postsPerPage}
              setPostPerPage={setPostPerPage}
              searchText={searchText}
              setSearchText={setSearchText}
              handleAddNew={handleAddNew}
              btnText="Variable"
            />
            <ReactTable
              columns={columns}
              data={filteredPosts}
              pageRows={postsPerPage}
            />
          </>
        )}
      </div>
      {showAddNewKey && (
        <AddNewKey
          setShowAddNewUser={setShowAddNewKey}
          editKey={editKey}
          setEditKey={setEditKey}
        />
      )}
    </ContentWrapper>
  );
};

export default ApiKeys;
