import React, { Children, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import makeAnimated from "react-select/animated";

import Avatar from "../../Components/Avatar/Avatar";
import { Col, Dropdown, Spinner } from "react-bootstrap";
import { PrimaryButton } from "../../Components/Buttons/Buttons";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts/Alerts";
import isEmpty from "validator/es/lib/isEmpty";
import { useUpdateSpaceUsersMutation } from "../../services/spaceService";

const animatedComponents = makeAnimated();

const ShareModal = ({ setShowModal, children }) => {
  const handleSubmit = () => {};

  return (
    <div
      className="modal fade show"
      id="shareProject"
      tabIndex={-1}
      style={{ display: "block" }}
      data-select2-id="shareProject"
      aria-modal="true"
      role="dialog"
    >
      <div
        className="modal-dialog modal-lg modal-simple modal-enable-otp modal-dialog-centered"
        data-select2-id={88}
      >
        <div className="modal-content p-3 p-md-5" data-select2-id={87}>
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setShowModal(false)}
            />
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
