export const agentObj = {
  config: {
    use_llm_config: false,
    use_code_execution_config: false,
    code_execution_config: false,
    default_auto_reply: "",
    human_input_mode: "NEVER",
    is_termination_msg: null,
    llm_config: {
      cache_seed: null,
      config_list: [],
      temperature: 0.1,
      timeout: null,
    },
    max_consecutive_auto_reply: 8,
    system_message: "",
  },
  skills: [],
  type: "assistant",
  user_id: "guestuser@gmail.com",
  name: "",
};
