import React from "react";

import styles from "./searchfilter.module.css";

const SearchFilter = ({
  searchText,
  setSearchText,
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  // const count = preGlobalFilteredRows.length ? preGlobalFilteredRows.length : 0;
  // const [value, setValue] = React.useState(globalFilter);
  // const onChange = useAsyncDebounce((value) => {
  //   setGlobalFilter(value || undefined);
  // }, 200);

  return (
    <>
      <div id="DataTables_Table_0_filter">
        <label>
          <input
            type="search"
            className="form-control"
            placeholder="Search.."
            aria-controls="DataTables_Table_0"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              // setValue(e.target.value);
              // onChange(e.target.value);
            }}
          />
        </label>
      </div>
    </>
  );
};

export default SearchFilter;
