import React from "react";

const PageNotFound = () => {
  return (
    <div className="misc-wrapper">
      <h2 className="mb-1 mt-4">Page Not Found :(</h2>
      <p className="mb-4 mx-2">
        Oops! 😖 The requested URL was not found on this server.
      </p>
      <a
        href="index.html"
        className="btn btn-primary mb-4 waves-effect waves-light"
      >
        Back to home
      </a>
      <div className="mt-4">
        <img
          src="../../assets/img/illustrations/page-misc-error.png"
          alt="page-misc-error"
          width={225}
          className="img-fluid"
        />
      </div>
    </div>
  );
};

export default PageNotFound;
