import React, { useRef, useState } from "react";
import Banner from "../../Components/Banner/Banner";
import { Row, Spinner } from "react-bootstrap";

import InputField, {
  SelectInput,
  Textarea,
} from "../../Components/Input/Input";
import Editor from "@monaco-editor/react";
import { Wizard, useWizard } from "react-use-wizard";
import isEmpty from "validator/es/lib/isEmpty";

import { useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams } from "react-router-dom";

import { CubeSpinner } from "../../Components/Spinners/Spinners";
import styles from "./collections.module.css";
import { ErrorAlert } from "../../Components/Alerts/Alerts";
import {
  useAddCollectionMutation,
  useAddScrapeCollectionMutation,
  useGetCollectionsByIdQuery,
  useUpdateCollectionMutation,
} from "../../services/collectionService";
import { useSelector } from "react-redux";
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";

import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import FilePondPluginFileMetadata from "filepond-plugin-file-metadata";
import authJson from "../../utils/auth.json";
import { beautifyJson, getStandardErrMsg } from "../../utils/utils";
import BackButton from "../../Components/BackButton/BackButton";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileMetadata
);

const MySwal = withReactContent(Swal);

const methodOptions = [
  { value: "upload", label: "Upload Files" },
  { value: "web_scrapping", label: "Web Scrapping" },
];

const AddNewCollection = () => {
  const navigate = useNavigate();
  const { collection_id } = useParams();
  const currentSpace = useSelector((state) => state.defaultSpace.data);
  const [files, setFiles] = useState([]);
  const [auth, setAuth] = useState(authJson);

  const skip = collection_id ? false : true;

  const { data, error, isFetching } = useGetCollectionsByIdQuery(
    collection_id,
    {
      skip,
    }
  );

  const editorRef = useRef(null);
  const authRef = useRef(null);

  const [activeStep, setActiveStep] = useState(0);
  const [collection, setCollection] = useState({
    name: "",
    model_name: "textembedding-gecko@001",
    method: "upload",
    authJson: authJson,
  });

  useEffect(() => {
    if (collection_id && data) {
      setCollection({ name: data.name, model_name: data.type });
    }
  }, [data, collection_id]);

  const [addCollection, addCollectionInfo] = useAddCollectionMutation();
  const [addScrapeCollection, addScrapeCollectionInfo] =
    useAddScrapeCollectionMutation();

  const [updateCollection, updateCollectionInfo] =
    useUpdateCollectionMutation();

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
    setTimeout(function () {
      editor.getAction("editor.action.formatDocument").run();
    }, 300);
  }

  function handleAuthEditorDidMount(editor, monaco) {
    console.log(editor, "editor");
    authRef.current = editor;

    setAuth(authRef.current.getValue());

    setTimeout(function () {
      editor.getAction("editor.action.formatDocument").run();
    }, 300);
  }

  useEffect(() => {
    if (addCollectionInfo.isSuccess || updateCollectionInfo.isSuccess) {
      MySwal.fire({
        title: "Success!",
        text: "We have started the operation. It could take some time to reflect the file",
        icon: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/Collections");
        }
      });
    } else if (addCollectionInfo.isError || updateCollectionInfo.isError) {
      MySwal.fire({
        title: "",
        text: "Operation Failed.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }, [
    addCollectionInfo.isError,
    addCollectionInfo.isSuccess,
    navigate,
    updateCollectionInfo.isError,
    updateCollectionInfo.isSuccess,
  ]);

  useEffect(() => {
    if (addScrapeCollectionInfo.isSuccess) {
      MySwal.fire({
        title: "Success!",
        text: "We have started the operation. It could take some time to reflect the file",
        icon: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/Collections");
        }
      });
    } else if (addScrapeCollectionInfo.isError) {
      MySwal.fire({
        title: "",
        text: "Operation Failed.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }, [
    addScrapeCollectionInfo.isSuccess,
    navigate,
    addScrapeCollectionInfo.isError,
  ]);

  function showValue() {
    const body = {
      request: {
        space_id: collection_id ? data.space_id : currentSpace,
        collection_id: collection_id ? collection_id : -1,
        collection_name: collection.name,
        model_name: collection.model_name,
        meta_data: JSON.parse(editorRef.current.getValue()),
        user_id: 0,
      },
    };
    if (collection.method !== "upload") {
      body.request["auth"] = {
        auth: JSON.parse(auth),
      };
    }

    let formData = new FormData();

    // Append files to formData
    files.forEach((file, index) => {
      console.log(file, file.file, "file array");
      if (collection.method === "upload") {
        formData.append("files", file.file);
      } else {
        formData.append("file", file.file);
      }
    });

    // Append the additional data to the formData
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        formData.append(key, JSON.stringify(body[key]));
      }
    }

    for (let pair of formData.entries()) {
      console.log(pair[0] + ":", pair[1]);
    }
    collection_id
      ? updateCollection({ body: body, collection_id: collection_id })
      : collection.method === "upload"
      ? addCollection(formData)
      : addScrapeCollection(formData);
  }

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-y">
        <Banner>{collection_id ? "Edit" : "Add"} Collection</Banner>

        <Row className="mt-5">
          <BackButton />

          {isFetching ? (
            <div className={styles.cubeLoader}>
              {" "}
              <CubeSpinner />
            </div>
          ) : error ? (
            <>
              <ErrorAlert>{getStandardErrMsg(error)}</ErrorAlert>
            </>
          ) : (
            <div className="bs-stepper wizard-vertical vertical mt-2">
              <div className="bs-stepper-header">
                <div
                  className={`step ${activeStep === 0 ? "active" : ""}`}
                  data-target="#account-details-1"
                >
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-circle">1</span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">New Collection</span>
                      <span className="bs-stepper-subtitle">
                        Enter Collection details
                      </span>
                    </span>
                  </button>
                </div>
                <div className="line" />
                <div
                  className={`step ${activeStep === 1 ? "active" : ""}`}
                  data-target="#personal-info-1"
                >
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-circle">2</span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">Meta Data</span>
                      <span className="bs-stepper-subtitle">
                        Enter JSON Format
                      </span>
                    </span>
                  </button>
                </div>
              </div>
              <div className="bs-stepper-content">
                <Wizard>
                  <Step1
                    setActiveStep={setActiveStep}
                    collection={collection}
                    setCollection={setCollection}
                    data={data}
                    files={files}
                    setFiles={setFiles}
                    handleAuthEditorDidMount={handleAuthEditorDidMount}
                    setAuth={setAuth}
                    authRef={authRef}
                    auth={auth}
                  />
                  <Step2
                    setActiveStep={setActiveStep}
                    showValue={showValue}
                    handleEditorDidMount={handleEditorDidMount}
                    addCollectionInfo={addCollectionInfo}
                    updateCollectionInfo={updateCollectionInfo}
                    data={data}
                  />
                </Wizard>
              </div>
            </div>
          )}
        </Row>
      </div>
    </div>
  );
};

const Step1 = ({
  setActiveStep,
  collection,
  setCollection,
  data,
  files,
  setFiles,
  handleAuthEditorDidMount,
  setAuth,
  authRef,
  auth,
}) => {
  const { nextStep, activeStep } = useWizard();
  const [error, setError] = useState({
    name: "",
    model_name: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "method") {
      setFiles([]);
    }
    setCollection((prevSpace) => ({
      ...prevSpace,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to an array
    setFiles(files); // Update state with the selected files
  };

  const handleUpdateFiles = (fileItems) => {
    // Get the names of the files that are being added
    const newFileNames = fileItems.map((fileItem) => fileItem.file.name);

    // Check for duplicates in the new files being added
    const uniqueFileItems = [];
    const fileNamesSet = new Set();

    for (let fileItem of fileItems) {
      if (!fileNamesSet.has(fileItem.file.name)) {
        uniqueFileItems.push(fileItem);
        fileNamesSet.add(fileItem.file.name);
      }
    }

    // Check for existing files with the same name
    // const existingFileNames = files.map((fileItem) => fileItem.file.name);
    // const duplicateInExistingFiles = uniqueFileItems.some((fileItem) =>
    //   existingFileNames.includes(fileItem.file.name)
    // );
    // if (duplicateInExistingFiles) {
    //   MySwal.fire({
    //     title: "",
    //     text: "This file already exists",
    //     icon: "error",
    //     confirmButtonText: "OK",
    //   });
    //   return;
    // }

    // Update the files state with unique files only
    setFiles(uniqueFileItems);
  };

  const handleNext = () => {
    let valid = true;
    let errors = {
      name: "",
      model_name: "",
      files: "",
    };

    if (isEmpty(collection.name)) {
      errors.name = "Please enter Collection name";
      valid = false;
    }
    if (isEmpty(collection.model_name)) {
      errors.type = "Please enter Collection description";
      valid = false;
    }
    if (files.length === 0) {
      errors.files = "Please select atleast one file";
      valid = false;
    }
    collection.method === "web_scrapping" &&
      setAuth(authRef.current.getValue());

    if (valid) {
      setActiveStep(activeStep + 1);
      nextStep();
    } else {
      setError(errors);
    }
  };

  return (
    <div id="account-details-1" className="content active dstepper-block">
      <div className="content-header mb-3">
        <h6 className="mb-0">Collection</h6>
        <small>Enter Collection Details</small>
      </div>
      <div className="card-body">
        <div className="mb-3 fv-plugins-icon-container">
          <InputField
            type="text"
            value={collection.name}
            placeholder="Collection Name"
            label="Collection   Name"
            name="name"
            onChange={handleChange}
          />
          <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
            {error.name}
          </div>
        </div>
        <div className="mb-3 fv-plugins-icon-container">
          <InputField
            type="text"
            value={collection.model_name}
            placeholder="Model Name"
            label="Embedding Model Name"
            name="model_name"
            onChange={handleChange}
            disabled
          />

          <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
            {error.model_name}
          </div>
        </div>
        <div className="mb-3  fv-plugins-icon-container">
          <SelectInput
            options={methodOptions}
            value={collection.method}
            onChange={handleChange}
            label="Select Stage"
            name={"method"}
          />
          <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
            {error.stage}
          </div>
        </div>

        {collection.method === "web_scrapping" && (
          <Editor
            height="50vh"
            defaultLanguage="json"
            defaultValue={
              data ? JSON.stringify(data.config) : beautifyJson(auth)
            }
            theme="vs-dark"
            options={{
              minimap: {
                enabled: false,
              },

              glyphMargin: false,
              folding: false,
              lineDecorationsWidth: 0,
              lineNumbersMinChars: 0,
              wordWrapColumn: 80,
              wordWrapMinified: true,
              autoIndent: "advanced",
              disableLayerHinting: false,
              automaticLayout: true,
              formatOnType: true,
              formatOnPaste: true,
            }}
            onMount={handleAuthEditorDidMount}
            className="mb-3 rounded"
          />
        )}
        <div className="mb-3  fv-plugins-icon-container">
          <FilePond
            files={files}
            allowMultiple={collection.method === "upload"}
            storeAsFile={true}
            onupdatefiles={handleUpdateFiles}
            name="files"
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            className=""
          />
          <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback ">
            {error.files}
          </div>
        </div>
        <div className="col-12 d-flex justify-content-between">
          <button
            className="btn btn-primary btn-next waves-effect waves-light"
            onClick={() => handleNext()}
          >
            {" "}
            <span className="align-middle d-sm-inline-block d-none me-sm-1">
              Next
            </span>{" "}
            <i className="ti ti-arrow-right" />
          </button>
        </div>
      </div>
    </div>
  );
};

const Step2 = ({
  setActiveStep,
  showValue,
  handleEditorDidMount,
  addCollectionInfo,
  updateCollectionInfo,
  data,
}) => {
  const { previousStep } = useWizard();

  const handlePrev = () => {
    setActiveStep(0);
    previousStep();
  };

  return (
    <div id="personal-info-1" className="content content active dstepper-block">
      <div className="content-header mb-3">
        <h6 className="mb-0">Meta Data</h6>
        <small>Enter meta data in JSON format.</small>
      </div>
      <Editor
        height="50vh"
        defaultLanguage="json"
        defaultValue={
          data ? JSON.stringify(data.config) : `{ "example": "example" }`
        }
        theme="vs-dark"
        options={{
          minimap: {
            enabled: false,
          },

          glyphMargin: false,
          folding: false,
          lineDecorationsWidth: 0,
          lineNumbersMinChars: 0,
          wordWrapColumn: 80,
          wordWrapMinified: true,
          autoIndent: "advanced",
          disableLayerHinting: false,
          automaticLayout: true,
          formatOnType: true, // Format the content when typing
          formatOnPaste: true, // Format the content when pasting
        }}
        onMount={handleEditorDidMount}
      />
      <div className="col-12 d-flex justify-content-between mt-3">
        <button
          className="btn btn-label-secondary btn-prev waves-effect"
          disabled=""
          onClick={() => handlePrev()}
        >
          {" "}
          <i className="ti ti-arrow-left me-sm-1 me-0" />
          <span className="align-middle d-sm-inline-block d-none">
            Previous
          </span>
        </button>
        {addCollectionInfo.isLoading || updateCollectionInfo.isLoading ? (
          <button
            class="btn btn-success btn-submit waves-effect waves-light disabled"
            onClick={() => showValue()}
          >
            Submitting{" "}
            <span style={{ marginLeft: "5px" }}>
              <Spinner classes={"spinner-border-sm"} />
            </span>
          </button>
        ) : (
          <button
            className="btn btn-success btn-submit waves-effect waves-light"
            onClick={() => showValue()}
          >
            Submit{" "}
          </button>
        )}
      </div>
    </div>
  );
};

export default AddNewCollection;
