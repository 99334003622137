import { getNestedValue } from "../../utils/utils";

export const generateSenderConfig = (senderObj) => {
  console.log(senderObj, "sender boj");
  return {
    codeExecutionConfig: {
      useDocker: getNestedValue(
        senderObj,
        ["config", "code_execution_config", "use_docker"],
        false
      ),
      workDir: getNestedValue(
        senderObj,
        ["config", "code_execution_config", "work_dir"],
        null
      ),
    },
    defaultAutoReply: getNestedValue(
      senderObj,
      ["config", "default_auto_reply"],
      "TERMINATE"
    ),
    humanInputMode: getNestedValue(
      senderObj,
      ["config", "human_input_mode"],
      "NEVER"
    ),
    isTerminationMsg: getNestedValue(
      senderObj,
      ["config", "is_termination_msg"],
      null
    ),
    cacheSeed: getNestedValue(
      senderObj,
      ["config", "llm_config", "cache_seed"],
      null
    ),
    configList: getNestedValue(
      senderObj,
      ["config", "llm_config", "config_list"],
      []
    ),
    temperature: getNestedValue(
      senderObj,
      ["config", "llm_config", "temperature"],
      0.1
    ),
    timeout: getNestedValue(
      senderObj,
      ["config", "llm_config", "timeout"],
      null
    ),
    useCodeExecutionConfig: getNestedValue(
      senderObj,
      ["config", "use_code_execution_config"],
      false
    ),
    useLlmConfig: getNestedValue(
      senderObj,
      ["config", "use_llm_config"],
      false
    ),

    maxConsecutiveAutoReply: getNestedValue(
      senderObj,
      ["config", "max_consecutive_auto_reply"],
      5
    ),
    name: getNestedValue(senderObj, ["config", "name"], ""),
    systemMessage: getNestedValue(senderObj, ["config", "system_message"], ""),
    description: getNestedValue(senderObj, ["description"], ""),
    skills: getNestedValue(senderObj, ["skills"], []),
    type: getNestedValue(senderObj, ["type"], "userproxy"),
    userId: getNestedValue(senderObj, ["user_id"], "default"),
  };
};
