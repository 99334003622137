// userAccess.js

import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getCookieByDomain } from "../utils/utils";

// Action type constant for updating user access
// export const UPDATE_USER_ACCESS = "userAccess/UPDATE_USER_ACCESS";

// Utility function to parse user access data from cookies
export const parseUserAccess = () => {
  const access = getCookieByDomain("access");
  if (access) {
    try {
      const parsedAccess = JSON.parse(
        access.replace(/\\/g, "").replace(/054/g, ",")
      );
      if (parsedAccess && parsedAccess.helios) {
        return parsedAccess.helios;
      }
      // Return a default value here if access.helios is not present
      return {};
    } catch (error) {
      console.error("Error parsing access data:", error);
    }
  }
  // Return a default value if access cookie is not present or cannot be parsed
  return {};
};

// Create a slice for user access
export const userSlice = createSlice({
  name: "userAccess",
  initialState: parseUserAccess(),
  reducers: {
    updateUserAccess: (state, action) => {
      return action.payload;
    },
  },
});

// Action creator for updating user access
// export const updateUserAccess = (data) => {
//   return {
//     type: UPDATE_USER_ACCESS,
//     payload: data,
//   };
// };

// // Subscribe to changes in the cookie and dispatch actions accordingly
// const subscribeToCookieChanges = (dispatch) => {
//   const interval = setInterval(() => {
//     const cookiesLength = Object.keys(Cookies.get()).length;
//     if (cookiesLength > 0) {
//       clearInterval(interval);
//       return;
//     }
//     const updatedUserAccess = parseUserAccess();
//     dispatch(updateUserAccess(updatedUserAccess));
//   }, 1000); // Adjust the interval as needed
//   return () => clearInterval(interval);
// };

export const { updateUserAccess } = userSlice.actions;
