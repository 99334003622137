import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const useOperationFeedback = (isSuccess, isError, navigatePath = "/") => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      MySwal.fire({
        title: "Success!",
        text: "Operation completed successfully.",
        icon: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(navigatePath);
        }
      });
    } else if (isError) {
      MySwal.fire({
        title: "",
        text: "Operation Failed.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }, [isSuccess, isError, navigate, navigatePath]);
};

export default useOperationFeedback;
