import { useState, useCallback } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSelector } from "react-redux";

const MySwal = withReactContent(Swal);

const useConfirmDelete = (deleteUser) => {
  const currentSpace = useSelector((state) => state.defaultSpace.data);

  const confirmDelete = useCallback(
    (user_email) => {
      const handleDelete = () => {
        MySwal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Delete",
        }).then((result) => {
          if (result.isConfirmed) {
            const body = { id: user_email, currentSpace };
            deleteUser(body);
          }
        });
      };

      handleDelete();
    },
    [deleteUser, currentSpace]
  );

  return confirmDelete;
};

export default useConfirmDelete;
