import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { aibotApiStdUrl, credentials, env } from "../constants/constants";
import { current } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getCookieByDomain } from "../utils/utils";

export const databaseApi = createApi({
  reducerPath: "databaseApi",

  baseQuery: fetchBaseQuery({
    baseUrl: aibotApiStdUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getCookieByDomain("crave_jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: credentials,
  }),
  keepUnusedDataFor: 86400,

  endpoints: (builder) => ({
    getDatabases: builder.query({
      query: (currentSpace) => `databases/${currentSpace}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Database", id })),
              { type: "Database", id: "DatabasesList" },
            ]
          : [{ type: "Database", id: "DatabasesList" }],
    }),
    getDatabasesById: builder.query({
      query: (id) => `databases_id/${id}`,
    }),
    addDatabase: builder.mutation({
      query: (body) => ({
        url: `databases/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "Database", id: "DatabasesList" }],
    }),
    updateDatabase: builder.mutation({
      query: ({ body, database_id }) => ({
        url: `databases/${database_id}`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(
        { body, database_id },
        { dispatch, queryFulfilled, getCacheEntry }
      ) {
        const patchResult = dispatch(
          databaseApi.util.updateQueryData(
            "getDatabases",
            body.space_id,
            (draft) => {
              console.log(database_id, "cache");
              const copy = current(draft);
              console.log(copy, "cp");
              const updated = copy.map((item) => {
                console.log("neeraj2");

                const newObj = { ...item };
                console.log(
                  item.database_id === database_id,
                  "item.database_id === database_id"
                );
                if (item.database_id === Number(database_id)) {
                  console.log("neeraj");
                  newObj["name"] = body.name;
                  newObj["type"] = body.type;
                  newObj["config"] = body.config;

                  console.log(newObj, "mew");
                }
                return newObj;
              });

              Object.assign(draft, updated);
            }
          )
        );
        const patchResult2 = dispatch(
          databaseApi.util.updateQueryData(
            "getDatabasesById",
            database_id,
            (draft) => {
              const copy = current(draft);
              console.log(copy, "cpcp");

              const newObj = { ...copy };
              newObj["name"] = body.name;
              newObj["type"] = body.type;
              newObj["config"] = body.config;

              console.log(newObj, "newObj");
              Object.assign(draft, newObj);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error, "error");
          patchResult.undo();
        }
      },
    }),

    deleteDatabase: builder.mutation({
      query: (body) => ({
        url: `databases/${body.id}/`,
        method: "DELETE",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getCacheEntry }) {
        let patchResult;
        try {
          patchResult = dispatch(
            databaseApi.util.updateQueryData(
              "getDatabases",
              body.currentSpace,
              (draft) => {
                const index = draft.findIndex((item) => {
                  console.log("🚀 ~ index ~ item:", item);

                  return item.database_id === body.id;
                });
                if (index > -1) {
                  draft.splice(index, 1);
                }
              }
            )
          );
        } catch (error) {
          console.log(error);
          if (patchResult && patchResult.undo) {
            patchResult.undo();
          }
        }
      },
    }),
  }),
});

export const updateGetDatabaseByIdCache = (id, newData) => {
  // databaseApi.endpoints.getDatabasesById.invalidateQuery(id);
  databaseApi.util.updateQueryData("getDatabasesById", id, () => newData);
};

export const {
  useGetDatabasesQuery,
  useDeleteDatabaseMutation,
  useAddDatabaseMutation,
  useGetDatabasesByIdQuery,
  useUpdateDatabaseMutation,
} = databaseApi;
