import React from "react";
import PostPerPage from "../PostPerPage/PostPerPage";
import styles from "./tablefilterwrapper.module.css";
import SearchFilter from "../SearchFilter/SearchFilter";
import { AddNewBtn } from "../Buttons/Buttons";

const FillterWrapper = ({
  postsPerPage,
  setPostPerPage,
  searchText,
  setSearchText,
  handleAddNew,
  btnText,
}) => {
  return (
    <div className="row me-2">
      <div className="col-md-2 m-auto">
        <div className={styles.postsPerPage}>
          <PostPerPage limit={postsPerPage} setPostPerPage={setPostPerPage} />
        </div>
      </div>
      <div className="col-md-10">
        <div
          className={`dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0 `}
        >
          <div className={styles.tableFilter}>
            <SearchFilter
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </div>
          <AddNewBtn handleAddNew={handleAddNew}>Add New {btnText}</AddNewBtn>
        </div>
      </div>
    </div>
  );
};

export default FillterWrapper;
