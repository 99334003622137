import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { aibotApiStdUrl, credentials, env } from "../constants/constants";
import { current } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getCookieByDomain } from "../utils/utils";

export const actionsApi = createApi({
  reducerPath: "actionsApi",

  baseQuery: fetchBaseQuery({
    baseUrl: aibotApiStdUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getCookieByDomain("crave_jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: credentials,
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getActions: builder.query({
      query: (currentSpace) => `actions/${currentSpace}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Action", id })),
              { type: "Action", id: "ActionsList" },
            ]
          : [{ type: "Action", id: "ActionsList" }],
    }),
    getActionsById: builder.query({
      query: (id) => `actions_id/${id}`,
    }),
    addAction: builder.mutation({
      query: (body) => ({
        url: `actions/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "Action", id: "ActionsList" }],
    }),
    updateAction: builder.mutation({
      query: ({ body, action_id }) => ({
        url: `actions/${action_id}`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(
        { body, action_id },
        { dispatch, queryFulfilled, getCacheEntry }
      ) {
        const patchResult = dispatch(
          actionsApi.util.updateQueryData(
            "getActions",
            body.space_id,
            (draft) => {
              const copy = current(draft);

              const updated = copy.map((item) => {
                const newObj = { ...item };

                if (item.action_id === Number(action_id)) {
                  newObj["title"] = body.title;
                  newObj["file_name"] = body.file_name;
                  newObj["share"] = body.share;
                  newObj["description"] = body.description;

                  const actionVersions = JSON.parse(
                    JSON.stringify(newObj["actions_version"])
                  );
                  actionVersions[0]["stage"] = body.stage;
                  actionVersions[0]["config"] = body.config;

                  newObj["actions_version"] = actionVersions;
                }
                return newObj;
              });

              Object.assign(draft, updated);
            }
          )
        );
        const patchResult2 = dispatch(
          actionsApi.util.updateQueryData(
            "getActionsById",
            action_id,
            (draft) => {
              const copy = current(draft);

              const newObj = { ...copy };
              newObj["title"] = body.title;
              newObj["file_name"] = body.file_name;
              newObj["share"] = body.share;
              newObj["description"] = body.description;

              const actionVersions = JSON.parse(
                JSON.stringify(newObj["actions_version"])
              );
              actionVersions[0]["stage"] = body.stage;
              actionVersions[0]["config"] = body.config;

              newObj["actions_version"] = actionVersions;

              Object.assign(draft, newObj);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error, "error");
          patchResult.undo();
        }
      },
    }),

    deleteAction: builder.mutation({
      query: (body) => ({
        url: `actions/${body.id}/`,
        method: "DELETE",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getCacheEntry }) {
        let patchResult;
        try {
          patchResult = dispatch(
            actionsApi.util.updateQueryData(
              "getActions",
              body.currentSpace,
              (draft) => {
                const index = draft.findIndex((item) => {
                  return item.action_id === body.id;
                });
                if (index > -1) {
                  draft.splice(index, 1);
                }
              }
            )
          );
        } catch (error) {
          console.log(error);
          if (patchResult && patchResult.undo) {
            patchResult.undo();
          }
        }
      },
    }),
  }),
});

export const updateGetActionByIdCache = (id, newData) => {
  // actionsApi.endpoints.getActionsById.invalidateQuery(id);
  actionsApi.util.updateQueryData("getActionsById", id, () => newData);
};

export const {
  useGetActionsQuery,
  useDeleteActionMutation,
  useAddActionMutation,
  useGetActionsByIdQuery,
  useUpdateActionMutation,
} = actionsApi;
