export const SuccessAlert = ({ children }) => {
  return (
    <div className="alert alert-success mx-2" role="alert">
      {children}
    </div>
  );
};

export const PrimayAlert = ({ children }) => {
  return (
    <div className="alert alert-primary mx-2" role="alert">
      {children}
    </div>
  );
};

export const ErrorAlert = ({ children }) => {
  return (
    <div className="alert alert-danger mx-2" role="alert">
      {children}
    </div>
  );
};
