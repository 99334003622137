import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Banner from "../../Components/Banner/Banner";
import {
  useDeleteUserMutation,
  useGetUsersQuery,
} from "../../services/userService";
import AddNewUser from "./AddNewUser";

import ReactTable from "../../Components/ReactTable/ReactTable";
import Avatar from "../../Components/Avatar/Avatar";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PostPerPage from "../../Components/PostPerPage/PostPerPage";
import { CubeSpinner } from "../../Components/Spinners/Spinners";
import { ErrorAlert } from "../../Components/Alerts/Alerts";
import styles from "./usermanagement.module.css";
import SearchFilter from "../../Components/SearchFilter/SearchFilter";
import isEmpty from "validator/es/lib/isEmpty";
import { filterByVal, getStandardErrMsg } from "../../utils/utils";

const MySwal = withReactContent(Swal);

// const role = [
//   {
//     userCount: Math.floor(Math.random() * 10) + 1,
//     roleName: "Platform Admin",
//     avatar: [avatar5, avatar12, avatar6],
//   },

//   {
//     userCount: Math.floor(Math.random() * 10) + 1,
//     roleName: "Space Admin",
//     avatar: [avatar5, avatar12, avatar6],
//   },
//   {
//     userCount: Math.floor(Math.random() * 10) + 1,
//     roleName: "Creator",
//     avatar: [avatar5, avatar12, avatar6],
//   },
//   {
//     userCount: Math.floor(Math.random() * 10) + 1,
//     roleName: "Viewer",
//     avatar: [avatar5, avatar12, avatar6],
//   },
//   {
//     userCount: Math.floor(Math.random() * 10) + 1,
//     roleName: "User",
//     avatar: [avatar5, avatar12, avatar6],
//   },
//   {
//     userCount: Math.floor(Math.random() * 10) + 1,
//     roleName: "Application",
//     avatar: [avatar5, avatar12, avatar6],
//   },
// ];

const UserManagement = () => {
  const [showAddNewUser, setShowAddNewUser] = useState(false);
  const [postsPerPage, setPostPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);

  const [editUser, setEditUser] = useState(["", "", "", ""]);

  const { data, error, isFetching } = useGetUsersQuery();
  const [deleteUser, deleteUserInfo] = useDeleteUserMutation();

  const handleEdit = useCallback(
    (userName, userEmail, userType, userSpaces) => {
      setEditUser([userName, userEmail, userType, userSpaces]);
      setShowAddNewUser(true);
    },
    []
  );

  const handleDelete = useCallback(
    (user_email) => {
      MySwal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteUser(user_email);
        }
      });
    },
    [deleteUser]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: " ",
        accessor: "",
        Cell: ({ cell }) => <Avatar>{cell.row.original.user_name}</Avatar>,
      },
      {
        Header: "Name",
        accessor: "user_name",
      },
      {
        Header: "Email",
        accessor: "user_email",
      },
      {
        Header: "Actions",
        Cell: ({ cell }) => (
          <>
            <Link
              to="#"
              className="text-body"
              onClick={() =>
                handleEdit(
                  cell.row.original.user_name,
                  cell.row.original.user_email,
                  cell.row.original.account_type,
                  cell.row.original.spaces
                )
              }
            >
              <i className="ti ti-edit ti-sm me-2" />
            </Link>
            <Link
              to="#"
              className="text-body delete-record"
              onClick={() => handleDelete(cell.row.original.user_email)}
            >
              <i className="ti ti-trash ti-sm mx-2" />
            </Link>
          </>
        ),
      },
    ],
    [handleDelete, handleEdit]
  );

  useEffect(() => {
    if (!isEmpty(searchText) && data && data.length > 0) {
      const matchingRes = filterByVal(data, searchText);
      setFilteredPosts(matchingRes);
    } else if (data && data.length > 0) {
      setFilteredPosts([...data]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  const handleAddNew = () => {
    setEditUser(["", "", ""]);
    setShowAddNewUser(true);
  };

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-y">
        <Banner>User Management</Banner>

        <div className="card mt-5">
          <div className="card-datatable">
            <div
              id="DataTables_Table_0_wrapper"
              className="dataTables_wrapper dt-bootstrap5 no-footer "
            >
              <div className="row me-2">
                <div className="col-md-2 m-auto">
                  <div className={styles.postsPerPage}>
                    <PostPerPage
                      limit={postsPerPage}
                      setPostPerPage={setPostPerPage}
                    />
                  </div>
                </div>
                <div className="col-md-10">
                  <div
                    className={`dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0 `}
                  >
                    <div className={styles.tableFilter}>
                      <SearchFilter
                        searchText={searchText}
                        setSearchText={setSearchText}
                      />
                    </div>
                    <div className="dt-buttons btn-group flex-wrap">
                      {" "}
                      <button
                        className="btn btn-secondary add-new btn-primary waves-effect waves-light"
                        tabIndex={0}
                        type="button"
                        onClick={() => handleAddNew()}
                      >
                        <span>
                          <i className="ti ti-plus me-0 me-sm-1 ti-xs" />
                          <span className="d-none d-sm-inline-block">
                            Add New User
                          </span>
                        </span>
                      </button>{" "}
                    </div>
                  </div>
                </div>
              </div>
              {isFetching ? (
                <div className={styles.cubeLoader}>
                  {" "}
                  <CubeSpinner />
                </div>
              ) : error ? (
                <>
                  <ErrorAlert>{getStandardErrMsg(error)}</ErrorAlert>
                </>
              ) : (
                <ReactTable
                  columns={columns}
                  data={filteredPosts}
                  pageRows={postsPerPage}
                />
                // <div className={styles.cubeLoader}>
                //   {" "}
                //   <CubeSpinner />
                // </div>
              )}
            </div>
          </div>
          {/* Offcanvas to add new user */}
          {showAddNewUser && (
            <AddNewUser
              setShowAddNewUser={setShowAddNewUser}
              editUser={editUser}
              setEditUser={setEditUser}
            />
          )}
        </div>
      </div>

      {/* <div className="content-backdrop fade" /> */}
    </div>
  );
};

// const RoleCard = ({ userCount, roleName, avatar }) => {
//   return (
//     <div className="card">
//       <div className="card-body">
//         <div className="d-flex justify-content-between">
//           <h6 className="fw-normal mb-2">Total {userCount} users</h6>
//           <ul className="list-unstyled d-flex align-items-center avatar-group mb-0">
//             {avatar.map((item) => (
//               <li
//                 key={makeid(5)}
//                 data-bs-toggle="tooltip"
//                 data-popup="tooltip-custom"
//                 data-bs-placement="top"
//                 title="Vinnie Mostowy"
//                 className="avatar avatar-sm pull-up"
//               >
//                 <img className="rounded-circle" src={item} alt="Avatar" />
//               </li>
//             ))}
//           </ul>
//         </div>
//         <div className="d-flex justify-content-between align-items-end mt-1">
//           <div className="role-heading">
//             <h4 className="mb-1">{roleName}</h4>
//             <Link
//               to="javascript:;"
//               data-bs-toggle="modal"
//               data-bs-target="#addRoleModal"
//               className="role-edit-modal"
//             >
//               <span>Edit Role</span>
//             </Link>
//           </div>
//           <Link to="javascript:void(0);" className="text-muted">
//             <i className="ti ti-copy ti-md" />
//           </Link>
//         </div>
//       </div>
//     </div>
//   );
// };

export default UserManagement;
