import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Banner from "../../Components/Banner/Banner";
import ReactTable from "../../Components/ReactTable/ReactTable";
import { CubeSpinner } from "../../Components/Spinners/Spinners";
import { ErrorAlert } from "../../Components/Alerts/Alerts";
import styles from "./collections.module.css";

import isEmpty from "validator/es/lib/isEmpty";

import {
  beautifyJson,
  filterByVal,
  getStandardErrMsg,
} from "../../utils/utils";

import ContentWrapper from "../../Components/ContentWrapper/ContentWrapper";
import useConfirmDelete from "../../utils/useConfirmDelete";
import FillterWrapper from "../../Components/TableFilterWrapper/TableFillterWrapper";

import {
  useGetCollectionFilesQuery,
  useDeleteCollectionMutation,
  useUpdateMetaDataMutation,
  useAddCollectionMutation,
  useAddScrapeCollectionMutation,
  useDeleteFileFromCollectionMutation,
  useLazyDownloadFileQuery,
} from "../../services/collectionService";
import ShareModal from "./ShareModal";

import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";

import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import FilePondPluginFileMetadata from "filepond-plugin-file-metadata";
import authJson from "../../utils/auth.json";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Editor } from "@monaco-editor/react";
import { Spinner } from "react-bootstrap";
import { apiDomain } from "../../constants/constants";
import { SelectInput } from "../../Components/Input/Input";

const MySwal = withReactContent(Swal);

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileMetadata
);

const methodOptions = [
  { value: "upload", label: "Upload Files" },
  { value: "web_scrapping", label: "Web Scrapping" },
];

const CollectionFilesScreen = () => {
  const navigate = useNavigate();
  const { collection_id } = useParams();

  const [postsPerPage, setPostPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [showFileMeta, setShowFileMeta] = useState(false);
  const [fileMetaId, setFileMetaId] = useState(null);
  const [method, setMethod] = useState("upload");
  const [auth, setAuth] = useState(authJson);

  const [metaData, setMetaData] = useState({});

  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState("");
  const [methodError, setMethodError] = useState("");

  const [addCollection, addCollectionInfo] = useAddCollectionMutation();
  const [addScrapeCollection, addScrapeCollectionInfo] =
    useAddScrapeCollectionMutation();

  const { data, error, isFetching } = useGetCollectionFilesQuery(collection_id);

  const [deletecollection, deletecollectionInfo] =
    useDeleteFileFromCollectionMutation();

  const [updateMetaData, updateMetaDataInfo] = useUpdateMetaDataMutation();

  const [
    triggerDownload,
    {
      data: fileBlob,
      isLoading: fileLoading,
      error: fileDonwloadErr,
      isSuccess,
    },
  ] = useLazyDownloadFileQuery();

  // const confirmDelete = useConfirmDelete(deletecollection);
  const editorRef = useRef(null);

  function handleEditorDidMount(editor, monaco) {
    console.log("neeraj");
    editorRef.current = editor;
    setTimeout(function () {
      editor.getAction("editor.action.formatDocument").run();
    }, 0);
  }

  useEffect(() => {
    if (addCollectionInfo.isSuccess) {
      setShowFileUpload(false);
      setFiles([]);
      setFileMetaId(null);
      MySwal.fire({
        title: "Success!",
        text: "We have started the operation. It could take some time to reflect the file",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else if (addCollectionInfo.isError) {
      MySwal.fire({
        title: "",
        text: "Operation Failed.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }, [addCollectionInfo.isError, addCollectionInfo.isSuccess]);

  useEffect(() => {
    if (addScrapeCollectionInfo.isSuccess) {
      setShowFileUpload(false);
      setFileMetaId(null);

      MySwal.fire({
        title: "Success!",
        text: "We have started the operation. It could take some time to reflect the file",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else if (addScrapeCollectionInfo.isError) {
      MySwal.fire({
        title: "",
        text: "Operation Failed.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }, [addScrapeCollectionInfo.isSuccess, addScrapeCollectionInfo.isError]);

  const handleDelete = useCallback(
    (id) => {
      const body = {
        collection_id: collection_id,
        id: encodeURIComponent(id),
      };
      MySwal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          deletecollection(body);
        }
      });
    },
    [collection_id, deletecollection]
  );

  const handleMeta = useCallback(
    (id) => {
      setFileMetaId(id);
      console.log(fileMetaId);

      setShowFileMeta(true);
    },
    [data, fileMetaId]
  );

  const getMetaData = (fileMetaId) => {
    const meta = data.filter((item) => item.file_location === fileMetaId);
    console.log(meta);
    return meta[0]?.meta;
  };

  const handleDownload = async (filePath, fileName) => {
    try {
      const { isSuccess, data, isError } = await triggerDownload(filePath);

      if (isSuccess && data) {
        // Check if data is a valid Blob
        if (data instanceof Blob && data.size > 0) {
          // Create a URL for the Blob
          const objectUrl = window.URL.createObjectURL(data);

          // Open the file in a new tab

          // Create an anchor element for downloading the file
          const anchor = document.createElement("a");
          anchor.href = objectUrl;
          anchor.download = fileName || "download"; // Use provided file name or default to 'download'
          document.body.appendChild(anchor);
          anchor.click();
          anchor.remove();

          // Revoke the object URL to free up resources
          window.URL.revokeObjectURL(objectUrl);
        } else {
          throw new Error("Received data is not a valid Blob or is empty");
        }
      } else if (isError) {
        console.error("Error during download:", isError);
      }
    } catch (error) {
      console.error("Error during download:", error);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Filename",
        accessor: "file_name",
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <div title={value} className={styles.collectionName}>
                {value}
              </div>
            </>
          );
        },
      },
      {
        Header: "extension",
        accessor: "extension",
      },

      {
        Header: "chunks",
        accessor: "chunks",
      },
      {
        Header: "Meta",
        accessor: "meta",
        Cell: ({ cell }) => (
          <i
            className="ti ti-file-code-2"
            style={{ cursor: "pointer" }}
            onClick={() => handleMeta(cell.row.original.file_location)}
          ></i>
        ),
      },
      {
        Header: "Created Time",
        accessor: "create_date_time",
      },
      {
        Header: "Updated Time",
        accessor: "update_date_time",
      },
      {
        Header: "Actions",
        Cell: ({ cell }) => (
          <>
            <Link
              to={"#"}
              className="text-body"
              onClick={() =>
                handleDownload(
                  cell.row.original.file_location,
                  cell.row.original.file_name
                )
              }
            >
              <i class="ti ti-download  ti-sm mr-2"></i>
            </Link>

            <Link
              to="#"
              className="text-body delete-record"
              onClick={() => handleDelete(cell.row.original.file_location)}
            >
              <i className="ti ti-trash ti-sm mx-2" />
            </Link>
          </>
        ),
      },
    ],
    [handleDelete, handleMeta]
  );

  const handleUpdateFiles = (fileItems) => {
    // Get the names of the files that are being added
    const newFileNames = fileItems.map((fileItem) => fileItem.file.name);

    // Check for duplicates in the new files being added
    const uniqueFileItems = [];
    const fileNamesSet = new Set();

    for (let fileItem of fileItems) {
      if (!fileNamesSet.has(fileItem.file.name)) {
        uniqueFileItems.push(fileItem);
        fileNamesSet.add(fileItem.file.name);
      }
    }

    // Check for existing files with the same name
    const existingFileNames = files.map((fileItem) => fileItem.file.name);

    setFiles(uniqueFileItems);
  };

  useEffect(() => {
    if (!isEmpty(searchText) && data && data.length > 0) {
      const matchingRes = filterByVal(data, searchText);
      setFilteredPosts(matchingRes);
    } else if (data && data.length > 0) {
      setFilteredPosts([...data]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  const handleAddNew = () => {
    setShowFileUpload(true);
  };

  const handleMetaData = () => {
    const body = {
      file_location: fileMetaId,
      meta: JSON.parse(editorRef.current.getValue()),
      collection_id: collection_id,
    };
    updateMetaData(body);
  };

  const handleAddFile = () => {
    let valid = true;

    if (!files || files.length === 0) {
      valid = false;
      // error.files = "Please select atleast one file";
      setFileError("Please select atleast one file");
    }

    if (valid) {
      // const meta = data.filter((item) => item.file_location === fileMetaId);
      console.log(data, "meta");
      const body = {
        request: {
          collection_id: collection_id,
          meta_data: JSON.parse(data[0].meta),
        },
      };

      if (method !== "upload") {
        body.request["auth"] = {
          auth: JSON.parse(authRef.current.getValue()),
        };
      }
      console.log(body, "body");
      let formData = new FormData();

      // Append files to formData
      files.forEach((file, index) => {
        console.log(file, file.file, "file array");
        if (method === "upload") {
          formData.append("files", file.file);
        } else {
          formData.append("file", file.file);
        }
      });

      // Append the additional data to the formData
      for (const key in body) {
        if (body.hasOwnProperty(key)) {
          formData.append(key, JSON.stringify(body[key]));
        }
      }
      method === "upload"
        ? addCollection(formData)
        : addScrapeCollection(formData);
    }
  };
  const authRef = useRef(null);

  function handleAuthEditorDidMount(editor, monaco) {
    console.log(editor, "editor");
    authRef.current = editor;

    setAuth(authRef.current.getValue());

    setTimeout(function () {
      editor.getAction("editor.action.formatDocument").run();
    }, 300);
  }

  const handleMethodChange = (e) => {
    setMethod(e.target.value);
    setFiles([]);
  };

  // console.log(fileBlob, isSuccess, fileDonwloadErr);

  return (
    <ContentWrapper>
      <Banner>Collection Files</Banner>
      <div className="card mt-5">
        <FillterWrapper
          postsPerPage={postsPerPage}
          setPostPerPage={setPostPerPage}
          searchText={searchText}
          setSearchText={setSearchText}
          handleAddNew={handleAddNew}
          btnText="Document"
        />
        {isFetching ? (
          <div className={styles.cubeLoader}>
            {" "}
            <CubeSpinner />
          </div>
        ) : error ? (
          <>
            <ErrorAlert>{getStandardErrMsg(error)}</ErrorAlert>
          </>
        ) : (
          <ReactTable
            columns={columns}
            data={filteredPosts}
            pageRows={postsPerPage}
          />
        )}
      </div>
      {showFileUpload && (
        <ShareModal setShowModal={setShowFileUpload}>
          {" "}
          <div className="text-center">
            <h3 className="mb-2">Add New Document</h3>
            <p></p>
          </div>
          <div className="mb-3  fv-plugins-icon-container">
            <SelectInput
              options={methodOptions}
              value={method}
              onChange={(e) => handleMethodChange(e)}
              label="Method"
              name={"method"}
            />
            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
              {methodError}
            </div>
          </div>
          {method === "web_scrapping" && (
            <Editor
              height="30vh"
              defaultLanguage="json"
              defaultValue={beautifyJson(auth)}
              theme="vs-dark"
              options={{
                minimap: {
                  enabled: false,
                },

                glyphMargin: false,
                folding: false,
                lineDecorationsWidth: 0,
                lineNumbersMinChars: 0,
                wordWrapColumn: 80,
                wordWrapMinified: true,
                autoIndent: "advanced",
                disableLayerHinting: false,
                automaticLayout: true,
                formatOnType: true,
                formatOnPaste: true,
              }}
              onMount={handleAuthEditorDidMount}
              className="mb-3 rounded"
            />
          )}
          <FilePond
            files={files}
            allowMultiple={method === "upload"}
            storeAsFile={true}
            onupdatefiles={handleUpdateFiles}
            name="files"
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            className=""
          />
          <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback ">
            {fileError}
          </div>
          {addCollectionInfo.isLoading || addScrapeCollectionInfo.isLoading ? (
            <button
              class="btn btn-success btn-submit waves-effect waves-light disabled"
              onClick={() => handleMetaData()}
            >
              Submitting{" "}
              <span style={{ marginLeft: "5px" }}>
                <Spinner classes={"spinner-border-sm"} />
              </span>
            </button>
          ) : (
            <button
              class="btn btn-success btn-submit waves-effect waves-light"
              onClick={() => handleAddFile()}
            >
              Submit{" "}
            </button>
          )}
        </ShareModal>
      )}
      {showFileMeta && (
        <ShareModal setShowModal={setShowFileMeta}>
          {" "}
          <div className="text-center">
            <h3 className="mb-2">Meta Data</h3>
            <Editor
              height="50vh"
              defaultLanguage="json"
              defaultValue={beautifyJson(getMetaData(fileMetaId))}
              theme="vs-dark"
              options={{
                minimap: {
                  enabled: false,
                },

                glyphMargin: false,
                folding: false,
                lineDecorationsWidth: 0,
                lineNumbersMinChars: 0,
                wordWrapColumn: 80,
                wordWrapMinified: true,
                autoIndent: "advanced",
                disableLayerHinting: false,
                automaticLayout: true,
                formatOnType: true, // Format the content when typing
                formatOnPaste: true, // Format the content when pasting
              }}
              onMount={handleEditorDidMount}
            />

            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback ">
              {fileError}
            </div>
            {updateMetaDataInfo.isLoading ? (
              <button
                class="btn btn-success btn-submit waves-effect waves-light disabled"
                onClick={() => handleMetaData()}
              >
                Submitting{" "}
                <span style={{ marginLeft: "5px" }}>
                  <Spinner classes={"spinner-border-sm"} />
                </span>
              </button>
            ) : (
              <button
                class="btn btn-success btn-submit waves-effect waves-light"
                onClick={() => handleMetaData()}
              >
                Submit{" "}
              </button>
            )}
          </div>
        </ShareModal>
      )}
    </ContentWrapper>
  );
};

export default CollectionFilesScreen;
