import React, { useRef, useState } from "react";
import Banner from "../../Components/Banner/Banner";
import { Row } from "react-bootstrap";

import { useEffect } from "react";

import { Link, useParams } from "react-router-dom";

import { CubeSpinner } from "../../Components/Spinners/Spinners";
import styles from "./aiworkforce.module.css";
import { ErrorAlert } from "../../Components/Alerts/Alerts";

import { useGetUsersQuery } from "../../services/userService";

import {
  useAddWorkforceMutation,
  useGetWorkforcesByIdQuery,
  useUpdateWorkforceMutation,
  useValidateWorkforceMutation,
} from "../../services/workforceService";
import { useGetModelsQuery } from "../../services/modelsService";
// import { useGetDatabasesQuery } from "../../services/databaseService";
import { useSelector, useDispatch } from "react-redux";

import { useGetCollectionsQuery } from "../../services/collectionService";
import useOperationFeedback from "../../utils/useOperationFeedback";
import {
  NewStepHeader,
  StepsHeader,
} from "../../Components/StepsHeader/StepsHeader";
import { getStandardErrMsg } from "../../utils/utils";

import ContentWrapper from "../../Components/ContentWrapper/ContentWrapper";
import { useGetActionsQuery } from "../../services/actionsService";

import { useGetAgentsQuery } from "../../services/agentsService";
import { receiverObj } from "./receiverObj";
import { senderObj } from "./senderObj";
import WorkforceStep1 from "./WorkforceStep1";
import WorkforceStep2 from "./WorkforceStep2";
import WorkforceStep3 from "./WorkforceStep3";
import WorkforceStep4 from "./WorkforceStep4";
import { isJSON } from "validator";
import BackButton from "../../Components/BackButton/BackButton";
import { ragObj } from "./rajObj";
import { heliosBot } from "../../constants/constants";
import { showNotification } from "../../services/notificationSlice";

const AddNewWorkforce = () => {
  const dispatch = useDispatch();
  const currentSpace = useSelector((state) => state.defaultSpace.data);

  const { workforce_id } = useParams();

  const skip = workforce_id ? false : true;

  const { data, error, isFetching } = useGetWorkforcesByIdQuery(workforce_id, {
    skip,
  });

  const {
    data: usersData = [],
    isFetching: isFetchingUsers,
    error: isUserError,
  } = useGetUsersQuery();

  const {
    data: modelData = [],
    isFetching: isFetchingModel,
    error: isModelError,
  } = useGetModelsQuery(currentSpace);

  // const {
  //   data: databaseData = [],
  //   isFetching: isFetchingDatabase,
  //   error: isDatabaseError,
  // } = useGetDatabasesQuery(currentSpace);

  const { data: actionData = [], isFetching: isFetchingAction } =
    useGetActionsQuery(currentSpace);

  const { data: collectionData = [], isFetching: isFetchingCollection } =
    useGetCollectionsQuery(currentSpace);

  const { data: agentsData = [], isFetching: isFetchingAgents } =
    useGetAgentsQuery(currentSpace);

  const editorRef = useRef(null);
  // const monaco = useMonaco();

  const [activeStep, setActiveStep] = useState(1);

  const [shake, setShake] = useState(false);

  const [workforce, setWorkforce] = useState({
    title: "",
    framework: "autogen",
    desc: "",
    share: false,
    users: [],
    model: "25",
    database: "26",
    stage: "",
    collection: "",
    type: "groupchat",
    ragAgent: false,
  });

  const [fieldError, setFieldError] = useState({
    title: "",
    framework: "",
    stage: "",
  });

  const [receiverError, setReceiverError] = useState({
    name: "",
    model: "",
  });

  const [senderError, setSenderError] = useState({
    name: "",
  });

  const [receiverConfig, setReceiverConfig] = useState(receiverObj);
  const [senderConfig, setSenderConfig] = useState(senderObj);
  const [ragConfig, setRagConfig] = useState(ragObj);

  const [mainConfig, setMainConfig] = useState({
    description: workforce.desc,
    name: workforce.title,
    summary_method: workforce.stage,
    type: workforce.type,
    receiver: receiverConfig,
    sender: senderConfig,
  });

  // console.log("🚀 ~ AddNewWorkforce ~ mainConfig:", mainConfig);

  useEffect(() => {
    if (workforce_id && data) {
      // console.log("🚀 ~ useEffect ~ data:", data);

      const userIds = data.users?.map((user) => user.user_id);
      const config =
        typeof data.workforce_versions[0].config === "string" &&
        isJSON(data.workforce_versions[0].config)
          ? JSON.parse(data.workforce_versions[0].config)
          : data.workforce_versions[0].config;

      console.log("🚀 ~ useEffect ~ config:", config, config.type);

      setWorkforce({
        title: data.title,
        framework: data.framework,
        desc: data.description,

        users: userIds,
        stage: data.workforce_versions[0].stage,
        share: data.share,
        type: data.workforce_versions[0].config.type,
        ragAgent: config.rag ? true : false,
      });

      setReceiverConfig((prevReceiverConfig) => ({
        ...prevReceiverConfig,
        ...config.receiver,
      }));
      setSenderConfig((prevSenderConfig) => ({
        ...prevSenderConfig,
        ...config.sender,
      }));
      setRagConfig((prevRagConfig) => ({
        ...prevRagConfig,
        ...config.rag,
      }));

      // setMainConfig((prevMainConfig) => ({
      //   // ...prevMainConfig,
      //   ...config,
      // }));
      setMainConfig(config);
    }
  }, [data, workforce_id]);

  const [addAction, addActionInfo] = useAddWorkforceMutation();
  const [validateAction, validateActionInfo] = useValidateWorkforceMutation();
  const [updateAction, updateActionInfo] = useUpdateWorkforceMutation();

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;

    setTimeout(function () {
      editor.getAction("editor.action.formatDocument").run();
    }, 300);
  }

  // const formatCode = useCallback(
  //   debounce(() => {
  //     if (editorRef.current) {
  //       const model = editorRef.current.getModel();
  //       if (model) {
  //         editorRef.current.getAction("editor.action.formatDocument").run();
  //       }
  //     }
  //   }, 300),
  //   [monaco]
  // );

  useOperationFeedback(
    addActionInfo.isSuccess,
    addActionInfo.isError,
    "/ai-workforce"
  );
  useOperationFeedback(
    updateActionInfo.isSuccess,
    updateActionInfo.isError,
    "/ai-workforce"
  );

  const handlePrev = (step) => {
    setFieldError({
      title: "",
      framework: "",
      stage: "",
    });
    setActiveStep(step);
  };

  const handleValidation = (activeStep) => {
    console.log("🚀 ~ handleValidation ~ activeStep:", activeStep, fieldError);
    let valid = true;
    let newErrors = {};
    if (activeStep === 2) {
      console.log("step 1");
      for (const field in fieldError) {
        newErrors[field] = "";
      }
      for (const field in fieldError) {
        if (!workforce[field]) {
          valid = false;
          newErrors[field] = `${
            field.charAt(0).toUpperCase() + field.slice(1)
          } is required.`;
        } else if (
          Array.isArray(workforce[field]) &&
          workforce[field].length === 0
        ) {
          // Additional validation for array fields
          valid = false;
          newErrors[field] = `${
            field.charAt(0).toUpperCase() + field.slice(1)
          } cannot be empty.`;
        }
      }
    } else if (activeStep === 3) {
      for (const field in receiverError) {
        newErrors[field] = "";
      }

      if (
        !receiverConfig.config.name ||
        receiverConfig.config.name.trim() === ""
      ) {
        valid = false;
        newErrors.name = "Name is required";
      }
      if (receiverConfig.groupchat_config.agents.length < 2) {
        valid = false;
        newErrors.agents = "Please select at least 2 agents.";
      }
      if (receiverConfig.config.llm_config?.config_list?.length === 0) {
        valid = false;
        newErrors.model = "Please select at least 1 model.";
      }
    }

    // If there are errors, update the state with the errors
    if (!valid) {
      setShake(true);
      activeStep === 2 ? setFieldError(newErrors) : setReceiverError(newErrors);
    } else {
      setShake(false);
    }

    return valid;
  };

  const handleNext = (step) => {
    if (handleValidation(step)) {
      setActiveStep(step);
      setFieldError({
        title: "",
        framework: "",
      });
    } else {
      console.log("invalid");
    }
  };

  useEffect(() => {
    setMainConfig((prevMainConfig) => {
      const updatedConfig = {
        ...prevMainConfig,
        description: workforce.desc,
        name: workforce.title,
        summary_method: workforce.stage,

        receiver: receiverConfig,
        sender: senderConfig,
      };

      if (workforce.ragAgent && workforce.ragAgent !== "false") {
        updatedConfig.rag = ragConfig;
      } else {
        delete updatedConfig.rag;
      }

      return updatedConfig;
    });
  }, [workforce, receiverConfig, senderConfig, ragConfig]);

  function showValue(custom = false, validateConfig = true) {
    let customCode = null;
    let valid = true;
    let newErrors = {};
    for (const field in fieldError) {
      newErrors[field] = "";
    }

    if (!senderConfig.config.name || senderConfig.config.name.trim() === "") {
      valid = false;
      newErrors.name = "Name is required";
    }
    if (!valid) {
      setShake(true);
      setSenderError(newErrors);
      return;
    } else {
      setShake(false);
    }

    if (typeof custom === "boolean" && custom === true) {
      customCode = JSON.parse(editorRef.current.getValue());
    }
    // custom ? console.log(customCode) : console.log(mainConfig);
    const body = {
      workforce_id: workforce_id ? workforce_id : null,
      workforce_version_id: workforce_id
        ? data.workforce_versions[0].workforce_version_id
        : null,
      space_id: workforce_id ? data.space_id : currentSpace,
      title: workforce.title,
      description: workforce.desc,
      framework: workforce.framework,
      share: workforce.share,
      parent_id: 0,
      platform_default: true,
      version: "1.0",
      stage: workforce.stage,
      config: custom ? customCode : mainConfig,
      users: workforce.users,
      model_id: 25,
      database_id: 26,
    };

    // if ((activeStep === 3 || activeStep === 4) && validateConfig === true) {
    //   handleValidate(body, (response) => {
    //     const { valid, detail } = response;

    //     if (valid === "False") {
    //       dispatch(
    //         showNotification({
    //           message: detail,
    //           type: "error",
    //         })
    //       );
    //     } else {
    //       workforce_id
    //         ? updateAction({
    //             body: body,
    //             workforce_id: workforce_id,
    //             usersData,
    //           })
    //         : addAction(body);
    //     }
    //   });
    // } else {
    //   workforce_id
    //     ? updateAction({ body: body, workforce_id: workforce_id, usersData })
    //     : addAction(body);
    // }
    workforce_id
      ? updateAction({ body: body, workforce_id: workforce_id, usersData })
      : addAction(body);
  }

  const handleValidate = (body, callback) => {
    validateAction(body)
      .unwrap()
      .then((response) => {
        console.log(response);
        callback(response);
      })
      .catch((error) => {
        console.error(error);
        callback({ valid: false, detail: "Validation failed" });
      });
  };

  return (
    <ContentWrapper>
      <Banner>{workforce_id ? "Edit" : "Add"} Workforce</Banner>
      <Row className="mt-5">
        <BackButton />
        {isFetching ||
        isFetchingUsers ||
        isFetchingModel ||
        isFetchingCollection ? (
          <div className={styles.cubeLoader}>
            {" "}
            <CubeSpinner />
          </div>
        ) : error || isUserError || isModelError ? (
          <>
            <ErrorAlert>{getStandardErrMsg(error)}</ErrorAlert>
          </>
        ) : (
          <div className="bs-stepper wizard-vertical vertical mt-2">
            <StepsHeader>
              <NewStepHeader
                activeStep={activeStep}
                stepNumber={1}
                stepTitle={"New Workforce"}
                stepSubtitle={"Enter basic details"}
                handleClick={handleNext}
              />
              <div className="line" />

              <NewStepHeader
                activeStep={activeStep}
                stepNumber={2}
                stepTitle={"Receiver Workforce"}
                stepSubtitle={"Enter receiver details"}
                handleClick={handleNext}
              />
              <div className="line" />

              <NewStepHeader
                activeStep={activeStep}
                stepNumber={3}
                stepTitle={"Sender Workforce"}
                stepSubtitle={"Enter sender details"}
                handleClick={handleNext}
              />
              <div className="line" />
              <NewStepHeader
                activeStep={activeStep}
                stepNumber={4}
                stepTitle={"Review Configurations"}
                stepSubtitle={"Review JSON"}
                handleClick={handleNext}
              />
              {workforce_id && (
                <Link
                  to={`${heliosBot}?id=${data?.workforce_versions[0]?.workforce_version_id}`}
                  target="_blank"
                >
                  <button
                    type="button"
                    className="btn  btn-success waves-effect waves-light mt-4"
                    style={{ width: "100%" }}
                  >
                    Run Workforce
                  </button>
                </Link>
              )}
            </StepsHeader>

            <div className={`bs-stepper-content ${shake ? styles.shake : ""}`}>
              {activeStep === 1 && (
                <WorkforceStep1
                  workforce={workforce}
                  setWorkforce={setWorkforce}
                  data={data}
                  usersData={usersData}
                  collectionData={collectionData}
                  isFetchingUsers={isFetchingUsers}
                  handleNext={handleNext}
                  error={fieldError}
                  ragConfig={ragConfig}
                  setRagConfig={setRagConfig}
                  isFetchingCollection={isFetchingCollection}
                />
              )}
              {activeStep === 2 && (
                <WorkforceStep2
                  handleEditorDidMount={handleEditorDidMount}
                  receiverConfig={receiverConfig}
                  collectionData={collectionData}
                  isFetchingCollection={isFetchingCollection}
                  modelData={modelData}
                  actionData={actionData}
                  isFetchingModel={isFetchingModel}
                  isFetchingAction={isFetchingAction}
                  setReceiverConfig={setReceiverConfig}
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  agentsData={agentsData}
                  isFetchingAgents={isFetchingAgents}
                  error={receiverError}
                />
              )}
              {activeStep === 3 && (
                <WorkforceStep3
                  handleEditorDidMount={handleEditorDidMount}
                  senderConfig={senderConfig}
                  collectionData={collectionData}
                  isFetchingCollection={isFetchingCollection}
                  modelData={modelData}
                  actionData={actionData}
                  isFetchingModel={isFetchingModel}
                  isFetchingAction={isFetchingAction}
                  setSenderConfig={setSenderConfig}
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  agentsData={agentsData}
                  isFetchingAgents={isFetchingAgents}
                  addActionInfo={addActionInfo}
                  updateActionInfo={updateActionInfo}
                  validateActionInfo={validateActionInfo}
                  showValue={showValue}
                  error={senderError}
                />
              )}
              {activeStep === 4 && (
                <WorkforceStep4
                  setActiveStep={setActiveStep}
                  showValue={showValue}
                  handleEditorDidMount={handleEditorDidMount}
                  addActionInfo={addActionInfo}
                  updateActionInfo={updateActionInfo}
                  validateActionInfo={validateActionInfo}
                  data={mainConfig}
                  handlePrev={handlePrev}
                  workforce={workforce}
                  senderConfig={senderConfig}
                  receiverConfig={receiverConfig}
                />
              )}
            </div>
          </div>
        )}
      </Row>
    </ContentWrapper>
  );
};

export default AddNewWorkforce;
