import React, { useRef, useState } from "react";
import Banner from "../../Components/Banner/Banner";
import { Row } from "react-bootstrap";

import InputField, { SelectInput } from "../../Components/Input/Input";
import Editor from "@monaco-editor/react";

import isEmpty from "validator/es/lib/isEmpty";

import { useEffect } from "react";

import { useParams } from "react-router-dom";

import { CubeSpinner } from "../../Components/Spinners/Spinners";
import styles from "./database.module.css";
import { ErrorAlert } from "../../Components/Alerts/Alerts";
import {
  useAddDatabaseMutation,
  useGetDatabasesByIdQuery,
  useUpdateDatabaseMutation,
} from "../../services/databaseService";
import { useSelector } from "react-redux";
import {
  NewStepHeader,
  StepsHeader,
} from "../../Components/StepsHeader/StepsHeader";
import { databaseConfigs } from "./databaseConfig";

import {
  LoadingButton,
  PrimaryButton,
  SecondaryButton,
} from "../../Components/Buttons/Buttons";
import CurrentStepHeading from "../../Components/CurrentStepHeading/CurrentStepHeading";
import useOperationFeedback from "../../utils/useOperationFeedback";
import ContentWrapper from "../../Components/ContentWrapper/ContentWrapper";
import FieldWrapper from "../../Components/FieldWrapper/FieldWrapper";
import BackButton from "../../Components/BackButton/BackButton";
import { jsonEditorOptions } from "../../utils/jsonEditorOption";
import { getStandardErrMsg } from "../../utils/utils";
const AddNewDatabase = () => {
  const { database_id } = useParams();

  const currentSpace = useSelector((state) => state.defaultSpace.data);

  const skip = database_id ? false : true;

  const { data, error, isFetching } = useGetDatabasesByIdQuery(database_id, {
    skip,
  });

  const editorRef = useRef(null);

  const [activeStep, setActiveStep] = useState(1);
  const [databaseConfig, setDatabaseConfig] = useState();

  const [database, setDatabase] = useState({
    name: "",
    type: "",
  });

  const [fieldError, setFieldError] = useState({
    name: "",
    type: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (database_id && data) {
      setDatabase({ name: data.name, type: data.type, ...data.config });
    }
  }, [data, database_id]);

  const [addDatabase, addDatabaseInfo] = useAddDatabaseMutation();
  const [updateDatabase, updateDatabaseInfo] = useUpdateDatabaseMutation();

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
    setTimeout(function () {
      editor.getAction("editor.action.formatDocument").run();
    }, 300);
  }

  useOperationFeedback(
    addDatabaseInfo.isSuccess,
    addDatabaseInfo.isError,
    "/database"
  );
  useOperationFeedback(
    updateDatabaseInfo.isSuccess,
    updateDatabaseInfo.isError,
    "/database"
  );

  const handlePrev = () => {
    setActiveStep(1);
    // previousStep();
  };

  const handleNext = () => {
    let valid = true;
    let errors = {
      name: "",
      type: "",
    };

    if (isEmpty(database.name)) {
      errors.name = "Please enter database name";
      valid = false;
    }
    if (isEmpty(database.type)) {
      errors.type = "Please enter database type";
      valid = false;
    }
    // Validate dynamically generated fields
    if (
      database.type &&
      database.type !== "custom" &&
      databaseConfigs[database.type] &&
      Array.isArray(databaseConfigs[database.type].fields)
    ) {
      databaseConfigs[database.type].fields.forEach((field) => {
        if (field.required && isEmpty(database[field.name])) {
          errors[field.name] = `Please enter ${field.label.toLowerCase()}`;
          valid = false;
        }
      });
    }

    if (valid) {
      if (database.type === "custom") {
        const jsonObject = { example: "example" };
        setDatabaseConfig(jsonObject);
        setActiveStep(activeStep + 1);
      } else {
        // jsonObject = { example: "example" };
        showValue();
      }
    } else {
      setFieldError(errors);
    }
  };

  function showValue() {
    let jsonObject;
    if (database.type !== "custom") {
      jsonObject = Object.entries(database).reduce((acc, [key, value]) => {
        if (key !== "type" && key !== "name") {
          acc[key] = value;
        }
        return acc;
      }, {});
    }

    const body = {
      space_id: database_id ? data.space_id : currentSpace,
      name: database.name,
      type: database.type,
      config:
        database.type === "custom"
          ? JSON.parse(editorRef.current.getValue())
          : jsonObject,
    };

    console.log("🚀 ~ showValue ~ body:", body);

    database_id
      ? updateDatabase({ body: body, database_id: database_id })
      : addDatabase(body);
  }

  return (
    <ContentWrapper>
      <Banner>{database_id ? "Edit" : "Add"} Database</Banner>

      <Row className="mt-5">
        <BackButton />
        {isFetching ? (
          <div className={styles.cubeLoader}>
            {" "}
            <CubeSpinner />
          </div>
        ) : error ? (
          <>
            <ErrorAlert>{getStandardErrMsg(error)}</ErrorAlert>
          </>
        ) : (
          <div className="bs-stepper wizard-vertical vertical mt-2">
            <StepsHeader>
              <NewStepHeader
                activeStep={activeStep}
                stepNumber={1}
                stepTitle={"New Database"}
                stepSubtitle={"Enter database details"}
                handleClick={handlePrev}
              />
              <div className="line" />

              <NewStepHeader
                activeStep={activeStep}
                stepNumber={2}
                stepTitle={"Configuration"}
                stepSubtitle={"Enter database configuration"}
                handleClick={handleNext}
                disabled={database.type !== "custom"}
              />
            </StepsHeader>

            <div className="bs-stepper-content">
              {activeStep === 1 && (
                <Step1
                  database={database}
                  setDatabase={setDatabase}
                  error={fieldError}
                  handleNext={handleNext}
                  showValue={showValue}
                  addDatabaseInfo={addDatabaseInfo}
                  updateDatabaseInfo={updateDatabaseInfo}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                />
              )}
              {activeStep === 2 && (
                <Step2
                  showValue={showValue}
                  handleEditorDidMount={handleEditorDidMount}
                  addDatabaseInfo={addDatabaseInfo}
                  updateDatabaseInfo={updateDatabaseInfo}
                  data={databaseConfig}
                  handlePrev={handlePrev}
                />
              )}
            </div>
          </div>
        )}
      </Row>
    </ContentWrapper>
  );
};

const Step1 = ({
  database,
  setDatabase,
  error,
  handleNext,
  showValue,
  addDatabaseInfo,
  updateDatabaseInfo,
  showPassword,
  setShowPassword,
}) => {
  const options = Object.entries(databaseConfigs).map(([key, value]) => {
    return { value: key, label: value.label };
  });
  options.unshift({ value: "", label: "Select" });
  options.push({ value: "custom", label: "Custom" });

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "type") {
      if (value && value !== "custom" && databaseConfigs[value]) {
        const updatedFields = databaseConfigs[value].fields.reduce(
          (acc, field) => {
            acc[field.name] = "";
            return acc;
          },
          {}
        );
        console.log("🚀 ~ handleChange ~ updatedFields:", updatedFields);
        setDatabase({
          name: database.name,
          type: value,
          ...updatedFields,
        });
      } else {
        setDatabase({
          name: database.name,
          type: value,
        });
      }
    } else {
      setDatabase((prevDatabase) => ({
        ...prevDatabase,
        [name]: value,
      }));
    }
  };

  return (
    <>
      <CurrentStepHeading
        title="Database"
        titleSummary="Enter database details"
      />
      <FieldWrapper errorMsg={error.name}>
        <InputField
          type="text"
          value={database.name}
          placeholder="Database Name"
          label="Database   Name"
          name="name"
          onChange={handleChange}
        />
      </FieldWrapper>
      <FieldWrapper errorMsg={error.type}>
        <SelectInput
          options={options}
          value={database.type}
          onChange={handleChange}
          label="Select Stage"
          name={"type"}
        />
      </FieldWrapper>
      {database.type &&
        database.type !== "custom" &&
        databaseConfigs[database.type].fields.map((field, index) => (
          <FieldWrapper errorMsg={error[field.name]} key={index}>
            <InputField
              type={
                field.type === "password" && showPassword ? "text" : field.type
              }
              value={database[field.name]}
              placeholder={field.placeholder}
              label={field.label}
              name={field.name}
              onChange={handleChange}
              icon={
                field.type === "password" &&
                (showPassword ? "ti ti-eye-off" : "ti ti-eye")
              }
              onIconClick={
                field.type === "password"
                  ? () => setShowPassword(!showPassword)
                  : false
              }
            />
          </FieldWrapper>
        ))}
      {database.type === "custom" ? (
        <PrimaryButton handleClick={handleNext}>
          Next <i className="ti ti-arrow-right" />
        </PrimaryButton>
      ) : addDatabaseInfo.isLoading || updateDatabaseInfo.isLoading ? (
        <LoadingButton />
      ) : (
        <PrimaryButton handleClick={handleNext} variant="submit">
          Submit{" "}
        </PrimaryButton>
      )}
    </>
  );
};

const Step2 = ({
  showValue,
  handleEditorDidMount,
  addDatabaseInfo,
  updateDatabaseInfo,
  data,
  handlePrev,
}) => {
  return (
    <>
      <CurrentStepHeading
        title="Configuration"
        titleSummary="Enter configuration in JSON format."
      />

      <Editor
        height="50vh"
        defaultLanguage="json"
        defaultValue={data && JSON.stringify(data)}
        theme="vs-dark"
        options={jsonEditorOptions}
        onMount={handleEditorDidMount}
      />
      <div className="col-12 d-flex justify-content-between mt-3">
        <SecondaryButton handleClick={handlePrev}>
          {" "}
          <i className="ti ti-arrow-left me-sm-1 me-0" /> Previous
        </SecondaryButton>

        {addDatabaseInfo.isLoading || updateDatabaseInfo.isLoading ? (
          <LoadingButton />
        ) : (
          <PrimaryButton handleClick={showValue} variant="submit">
            Submit{" "}
          </PrimaryButton>
        )}
      </div>
    </>
  );
};

export default AddNewDatabase;
