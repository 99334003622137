import React from "react";

const FieldWrapper = ({ children, errorMsg = "" }) => {
  return (
    <div className="mb-3 fv-plugins-icon-container">
      {children}
      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
        {errorMsg}
      </div>
    </div>
  );
};

export default FieldWrapper;
